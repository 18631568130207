import "./DataTable.scss"
import { DataGrid } from '@mui/x-data-grid';
import { useEffect, useState } from "react";
import { relic, lightConeRows, property } from "../utils";
import {
  addDoc,
  collection,
} from "@firebase/firestore";
import { db } from "../../firebase";
import { LanguageContext } from "../../App";
import { useContext } from "react";
import { Search } from "@mui/icons-material";
const trophyIcon = require("../image/icon/achivement.webp");
const memoryOfChaosIcon = require("../image/icon/forgotten_hall.png");

const Datatable = ({ type, orderStatus, orderCreatedDate, overrideDataRow, selectedRows, setSelectedRows, characterId, sortModel, setPopupUid }) => {

  
const languageContext = useContext(LanguageContext);
  var [info, setInfo] = useState({
    pageSize: 10,
    title: "",
    addNewButton: "none",
    addNewLink: "",
    exportButton: "none",
    checkboxSelection: false
  });
  
  const [finalUserRows, setFinalUserRows] = useState([]);
  const [userColumns, setUserColumns] = useState([]);
  const [randomSeedRanking, setRandomSeedRanking] = useState(0);

  // Filter Authorized Accounts for specific Users
  useEffect(() => {
    var tempInfo = {};

   function processData() {
      switch (type) {
        case "ranking":
          tempInfo = {
            pageSize: 10,
            title: "",
            addNewButton: "block",
            addNewLink: "new",
            exportButton: "block"
          };
          
          var rankingTranslation ={
            "name": {
              "zh-cn": "名字",
              "zh-tw": "名字",
              "en-us": "Name",
            },
            "eidolon": {
              "zh-cn": "星魂",
              "zh-tw": "星魂",
              "en-us": "Eidolon",
            },
            "custom_formula": {
              "zh-cn": "自定义公式",
              "zh-tw": "自定義公式",
              "en-us": "Custom Formula",
            },
            "full_crit_ratio": {
              "zh-cn": "完整暴击比率",
              "zh-tw": "完整暴擊比率",
              "en-us": "Full Crit Ratio",
            },
            "relic_crit_ratio": {
              "zh-cn": "遗器暴击比率",
              "zh-tw": "遺器暴擊比率",
              "en-us": "Relic Crit Ratio",
            },
            "light_cone": {
              "zh-cn": "光锥",
              "zh-tw": "光錐",
              "en-us": "Light Cone",
            },
            "relic_set": {
              "zh-cn": "遗器套装",
              "zh-tw": "遺器套裝",
              "en-us": "Relic Set",
            },
            "hp": {
              "zh-cn": "生命值",
              "zh-tw": "生命值",
              "en-us": "HP",
            },
            "att": {
              "zh-cn": "攻击力",
              "zh-tw": "攻擊力",
              "en-us": "ATT",
            },
            "def": {
              "zh-cn": "防御力",
              "zh-tw": "防禦力",
              "en-us": "DEF",
            },
            "spd": {
              "zh-cn": "速度",
              "zh-tw": "速度",
              "en-us": "SPD",
            },
            "o": {
              "zh-cn": "治疗量加成",
              "zh-tw": "治療量加成",
              "en-us": "Healing BST",
            },
            "eg": {
              "zh-cn": "能量恢复效率",
              "zh-tw": "能量恢復效率",
              "en-us": "Energy REG",
            },
            "eh": {
              "zh-cn": "效果命中",
              "zh-tw": "效果命中",
              "en-us": "Effect Hit",
            },
            "be": {
              "zh-cn": "击破特攻",
              "zh-tw": "擊破特攻",
              "en-us": "Break Effect",
            },
            "er": {
              "zh-cn": "效果抵抗",
              "zh-tw": "效果抵抗",
              "en-us": "Effect RES",
            },
            "elemental": {
              "zh-cn": "元素伤害",
              "zh-tw": "元素傷害",
              "en-us": "Elemental",
            },
            "updated_at": {
              "zh-cn": "更新于",
              "zh-tw": "更新於",
              "en-us": "Last Updated At"
            }
          }

          setFinalUserRows(overrideDataRow);
          setUserColumns(
            [
              { field: "id", headerName: "#", maxWidth: 70},
              { field: "name", headerName: rankingTranslation.name[languageContext.language], minWidth: 120 },
              { field: "eidolon", headerName: rankingTranslation.eidolon[languageContext.language], filterable: false,
              renderCell: (params) => {
                return (
                  <div key={Math.random() + params.row.HP} className="eidolon" style={{display: "flex"}}>
                    {
                      params.row.eidolon === 1 ? 
                      <div className="container">
                      <img alt="e1" className="image" src={characterId.e1}/>
                      </div> :
                      params.row.eidolon === 2 ? 
                      <div className="container">
                      <img alt="e1" className="image" src={characterId.e1}/>
                      <img alt="e2" className="image" src={characterId.e2}/>
                      </div> :
                      params.row.eidolon === 3 ? 
                      <div className="container">
                      <img alt="e1" className="image" src={characterId.e1}/>
                      <img alt="e2" className="image" src={characterId.e2}/>
                      <img alt="e3" className="image" src={characterId.e3}/>
                      </div> :
                      params.row.eidolon === 4 ? 
                      <div className="container">
                      <img alt="e1" className="image" src={characterId.e1}/>
                      <img alt="e2" className="image" src={characterId.e2}/>
                      <img alt="e3" className="image" src={characterId.e3}/>
                      <img alt="e4" className="image" src={characterId.e4}/>
                      </div> :
                      params.row.eidolon === 5 ? 
                      <div className="container">
                      <img alt="e1" className="image" src={characterId.e1}/>
                      <img alt="e2" className="image" src={characterId.e2}/>
                      <img alt="e3" className="image" src={characterId.e3}/>
                      <img alt="e4" className="image" src={characterId.e4}/>
                      <img alt="e5" className="image" src={characterId.e5}/>
                      </div> :
                      params.row.eidolon === 6 ? 
                      <div className="container">
                      <img alt="e1" className="image" src={characterId.e1}/>
                      <img alt="e2" className="image" src={characterId.e2}/>
                      <img alt="e3" className="image" src={characterId.e3}/>
                      <img alt="e4" className="image" src={characterId.e4}/>
                      <img alt="e5" className="image" src={characterId.e5}/>
                      <img alt="e6" className="image" src={characterId.e6}/>
                      </div> :
                      <p style={{color: "white"}}>0</p>
                    }
                  </div>
                );
              }},
              { field: "formula", headerName: rankingTranslation.custom_formula[languageContext.language], minWidth: 160, disableColumnMenu: true,
              renderCell: (params) => {
                return (
                  <div key={Math.random() + params.row.formula}>
                  <div className="property" style={{display: "flex"}}>
                      <img alt="formula" className="image" title={characterId.formula.name[languageContext.language]} src={characterId.formula.icon}/>
                      <p className="value">{params.row.formula}</p>
                  </div>
                  </div>
                );
              },},
              { field: "RelicSet", headerName: rankingTranslation.relic_set[languageContext.language], minWidth: 240, sortable: false, filterable: false,
              renderCell: (params) => {
                return (
                  <div className="relicSetHover" style={{display: "flex"}}>
                    <Search className="search"
                          onClick={() => {
                    setPopupUid(params.row.uid);
                  }}/>
                    {
                      typeof(params.row.RelicSet) !== "undefined" ? 
                      params.row.RelicSet.map((item, i) => {
                        return (
                          <div key={Math.random() + item.d} className="single-relic-set" style={{display: "flex"}}>
                              <img alt={item.name} className="image" src={item.image} title={item.name}/>
                              <p className="desc" style={{backgroundColor: item.colour}}>{item.d}</p>
                          </div>
                        )})
                        : <div></div>
                    }
                  </div>
                );
              },},
              { field: "LightCone", headerName: rankingTranslation.light_cone[languageContext.language], minWidth: 140, sortable: false, filterable: false,
              renderCell: (params) => {
                return (
                  <div style={{display: "flex"}}>
                    {
                      typeof(lightConeRows.find(lc => lc.id === params.row.LightCone.i)) !== "undefined" ? 
                      <div key={params.row.id} className="lightcone" style={{display: "flex"}}>
                          <img alt="lightcone" className="image" title={lightConeRows.find(lc => lc.id === params.row.LightCone.i).translation[languageContext.language]}  src={lightConeRows.find(lc => lc.id === params.row.LightCone.i).image}/>
                          
                          {
                            params.row.LightCone.r === 1 ? <div className="rank">I</div> :
                            params.row.LightCone.r === 2 ? <div className="rank">II</div> :
                            params.row.LightCone.r === 3 ? <div className="rank">III</div> :
                            params.row.LightCone.r === 4 ? <div className="rank">IV</div> :
                            params.row.LightCone.r === 5 ? <div className="rank V">V</div> :
                            <p></p>
                          }
                          <p>LV {params.row.LightCone.l}</p>
                      </div>
                        : <div></div>
                    }
                  </div>
                );
              },},
              { field: "CritRatio", headerName: rankingTranslation.full_crit_ratio[languageContext.language], minWidth: 220,
              renderCell: (params) => {
                return (
                  <label style={{color: "white"}}>
                    {String(params.row.CriticalRate).split('%')[0] + " CR : " + String(params.row.CriticalDamage).split('%')[0]  + " CD " 
                    + " | " + params.row.CritRatio + " CV"}
                  </label>
                );
              },},
              { field: "RelicCritRatio", headerName: rankingTranslation.relic_crit_ratio[languageContext.language], minWidth: 220,
              renderCell: (params) => {
                return (
                  <label style={{color: "white"}}>
                    {params.row.RelicCriticalRate + " CR : " + params.row.RelicCriticalDamage  + " CD " 
                    + " | " + params.row.RelicCritRatio + " CV"}
                  </label>
                );
              },},
              { field: "level", headerName: "LV", maxWidth: 80},
              { field: "HP", headerName: rankingTranslation.hp[languageContext.language],
              renderCell: (params) => {
                return (
                  <div key={Math.random() + params.row.HP} className="property" style={{display: "flex"}}>
                      <img alt="HP" className="image" src={property.find(id => id.id === "H").image}/>
                      <p className="value">{params.row.HP}</p>
                  </div>
                );
              }},
              { field: "Attack", headerName: rankingTranslation.att[languageContext.language],
              renderCell: (params) => {
                return (
                  <div key={Math.random() + params.row.Attack} className="property" style={{display: "flex"}}>
                      <img alt="Attack" className="image" src={property.find(id => id.id === "A").image}/>
                      <p className="value">{params.row.Attack}</p>
                  </div>
                );
              }},
              { field: "Defend", headerName: rankingTranslation.def[languageContext.language],
              renderCell: (params) => {
                return (
                  <div key={Math.random() + params.row.Defend} className="property" style={{display: "flex"}}>
                      <img alt="Defend" className="image" src={property.find(id => id.id === "D").image}/>
                      <p className="value">{params.row.Defend}</p>
                  </div>
                );
              }},
              { field: "Speed", headerName: rankingTranslation.spd[languageContext.language],
              renderCell: (params) => {
                return (
                  <div key={Math.random() + params.row.Speed} className="property" style={{display: "flex"}}>
                      <img alt="Speed" className="image" src={property.find(id => id.id === "S").image}/>
                      <p className="value">{params.row.Speed}</p>
                  </div>
                );
              }},
              { field: "OutgoingHealingBoost", headerName: rankingTranslation.o[languageContext.language],
              renderCell: (params) => {
                return (
                  <div key={Math.random() + params.row.OutgoingHealingBoost} className="property" style={{display: "flex"}}>
                      <img alt="OutgoingHealingBoost" className="image" src={property.find(id => id.id === "O").image}/>
                      <p className="value">{params.row.OutgoingHealingBoost}</p>
                  </div>
                );
              }},
              { field: "EnergyRegenerationRate", headerName: rankingTranslation.eg[languageContext.language],
              renderCell: (params) => {
                return (
                  <div key={Math.random() + params.row.EnergyRegenerationRate} className="property" style={{display: "flex"}}>
                      <img alt="EnergyRegenerationRate" className="image" src={property.find(id => id.id === "EG").image}/>
                      <p className="value">{params.row.EnergyRegenerationRate}</p>
                  </div>
                );
              }},
              { field: "EffectHitRate", headerName: rankingTranslation.eh[languageContext.language],
              renderCell: (params) => {
                return (
                  <div key={Math.random() + params.row.EffectHitRate} className="property" style={{display: "flex"}}>
                      <img alt="EffectHitRate" className="image" src={property.find(id => id.id === "EH").image}/>
                      <p className="value">{params.row.EffectHitRate}</p>
                  </div>
                );
              }},
              { field: "BreakEffect", headerName: rankingTranslation.be[languageContext.language],
              renderCell: (params) => {
                return (
                  <div key={Math.random() + params.row.BreakEffect} className="property" style={{display: "flex"}}>
                      <img alt="BreakEffect" className="image" src={property.find(id => id.id === "BE").image}/>
                      <p className="value">{params.row.BreakEffect}</p>
                  </div>
                );
              }},
              { field: "EffectResistance", headerName: rankingTranslation.er[languageContext.language],
              renderCell: (params) => {
                return (
                  <div key={Math.random() + params.row.EffectResistance} className="property" style={{display: "flex"}}>
                      <img alt="EffectResistance" className="image" src={property.find(id => id.id === "ER").image}/>
                      <p className="value">{params.row.EffectResistance}</p>
                  </div>
                );
              }},
              { field: "ElementalDamage", headerName: rankingTranslation.elemental[languageContext.language],
              renderCell: (params) => {
                return (
                  <div key={Math.random() + params.row.ElementalDamage} className="property" style={{display: "flex"}}>
                      <img alt="ElementalDamage" className="image" src={characterId.elementIcon}/>
                      <p className="value">{params.row.ElementalDamage}</p>
                  </div>
                );
              }},
              {
                field: "updated_at", headerName: rankingTranslation.updated_at[languageContext.language], type: 'string', minWidth: 160, flex: 1,
                valueGetter: ({ value }) => {
                  var currentDate = String(value).substring(18, 28);
                  return new Date(Number(currentDate) * 1000).toLocaleString('en-US',
                    {
                      year: 'numeric', month: 'short', day: 'numeric',
                      hour: '2-digit', minute: '2-digit', hourCycle: 'h23'
                    }
                  )
                },
              },
            ]
          );
          break;
          case "relic":
            tempInfo = {
              pageSize: 10,
              title: "",
              addNewButton: "block",
              addNewLink: "new",
              exportButton: "block"
            };
  
            var relicTranslation ={
              "region": {
                "zh-cn": "区域",
                "zh-tw": "區域",
                "en-us": "Region",
              },
              "name": {
                "zh-cn": "名字",
                "zh-tw": "名字",
                "en-us": "Name"
              },
              "relic_id": {
                "zh-cn": "遗器",
                "zh-tw": "遺器",
                "en-us": "Relic"
              },
              "main": {
                "zh-cn": "主属性",
                "zh-tw": "主屬性",
                "en-us": "Main"
              },
              "attack": {
                "zh-cn": "攻击",
                "zh-tw": "攻擊",
                "en-us": "ATK"
              },
              "attack_rate": {
                "zh-cn": "攻击 %",
                "zh-tw": "攻擊 %",
                "en-us": "ATK %"
              },
              "break_effect": {
                "zh-cn": "击破特攻",
                "zh-tw": "擊破特攻",
                "en-us": "Break Effect"
              },
              "cv": {
                "zh-cn": "遗器暴击比率",
                "zh-tw": "遺器暴擊比率",
                "en-us": "Relic Crit Ratio"
              },
              "critical_damage": {
                "zh-cn": "暴击伤害",
                "zh-tw": "暴擊傷害",
                "en-us": "Crit DMG"
              },
              "critical_rate": {
                "zh-cn": "暴击率",
                "zh-tw": "暴擊率",
                "en-us": "Crit Rate"
              },
              "defend": {
                "zh-cn": "防御力",
                "zh-tw": "防禦力",
                "en-us": "DEF"
              },
              "defend_rate": {
                "zh-cn": "防御力 %",
                "zh-tw": "防禦力 %",
                "en-us": "DEF %"
              },
              "energy_regeneration_rate": {
                "zh-cn": "能量恢复效率",
                "zh-tw": "能量恢復效率",
                "en-us": "Energy Regeneration Rate"
              },
              "effect_hit_rate": {
                "zh-cn": "效果命中",
                "zh-tw": "效果命中",
                "en-us": "Effect Hit Rate"
              },
              "effect_resistance": {
                "zh-cn": "效果抵抗",
                "zh-tw": "效果抵抗",
                "en-us": "Effect RES"
              },
              "hp": {
                "zh-cn": "生命力",
                "zh-tw": "生命力",
                "en-us": "HP"
              },
              "hp_rate": {
                "zh-cn": "生命力 %",
                "zh-tw": "生命力 %",
                "en-us": "HP %"
              },
              "outgoing_healing_boost": {
                "zh-cn": "治疗量加成",
                "zh-tw": "治療量加成",
                "en-us": "Outgoing Healing Boost"
              },
              "speed": {
                "zh-cn": "速度",
                "zh-tw": "速度",
                "en-us": "Speed"
              },
              "element_damage": {
                "zh-cn": "元素伤害",
                "zh-tw": "元素傷害",
                "en-us": "Elemental DMG"
              },
            }
  
            setFinalUserRows(overrideDataRow);
            setUserColumns(
              [
                { field: "no", headerName: "#", maxWidth: 60, disableColumnMenu: true, sortable: false, filterable: false},
                { field: "name", headerName: relicTranslation.name[languageContext.language], sortable: false, filterable: false, minWidth: 120},
                { field: "relic_id", headerName: relicTranslation.relic_id[languageContext.language], sortable: false, filterable: false, minWidth: 80, maxWidth: 80,
                renderCell: (params) => {
                  return (
                    <div key={Math.random() + params.row.relic_id} className="property" style={{display: "flex"}}>
                        <img title={relic.filter(id => id.id === params.row.relic_id)[0].translation[languageContext.language]} alt="main" className="image" 
                        src={relic.filter(id => id.id === params.row.relic_id)[0].image}/>
                    </div>
                  );
                }},
                { field: "main_property", headerName: relicTranslation.main[languageContext.language], sortable: false, filterable: false, 
                renderCell: (params) => {
                  return (
                    <div key={Math.random() + params.row.main_property_value} className="property" style={{display: "flex"}}>
                        <img title={property.filter(id => id.id === params.row.main_property)[0].translation[languageContext.language]} alt="main" className="image" src={property.find(id => id.id === params.row.main_property).image}/>
                        <p className="value">{params.row.main_property_value} {params.row.main_property_percentage === true ? " %" : ""}</p>
                    </div>
                  );
                }},
                { field: "cv", headerName: relicTranslation.cv[languageContext.language], minWidth: 200, maxWidth: 200, sortable: false, filterable: false,
                renderCell: (params) => {
                  return (
                    <label style={{color: "white"}}>
                      {Number(params.row.CR).toFixed(1) + " CR : " + Number(params.row.CD).toFixed(1)  + " CD " 
                      + " | " + Number(params.row.cv).toFixed(1) + " CV"}
                    </label>
                  );
                },},
                { field: "CR", headerName: relicTranslation.critical_rate[languageContext.language], sortable: false, filterable: false,
              renderCell: (params) => {
                return (
                  <div key={Math.random() + params.row.CR} className="property" style={{display: "flex"}}>
                      <img title={property.filter(id => id.id === "CR")[0].translation[languageContext.language]} alt="CR" className="image" src={property.find(id => id.id === "CR").image}/>
                      <p className="value">{Number(params.row.CR).toFixed(1)} %</p>
                  </div>
                );
              }},
                { field: "CD", headerName: relicTranslation.critical_damage[languageContext.language], sortable: false, filterable: false,
              renderCell: (params) => {
                return (
                  <div key={Math.random() + params.row.CD} className="property" style={{display: "flex"}}>
                      <img title={property.filter(id => id.id === "CD")[0].translation[languageContext.language]} alt="CD" className="image" src={property.find(id => id.id === "CD").image}/>
                      <p className="value">{Number(params.row.CD).toFixed(1)} %</p>
                  </div>
                );
              }},
              { field: "AR", headerName: relicTranslation.attack_rate[languageContext.language], sortable: false, filterable: false,
            renderCell: (params) => {
              return (
                <div key={Math.random() + params.row.AR} className="property" style={{display: "flex"}}>
                    <img title={property.filter(id => id.id === "A")[0].translation[languageContext.language]} alt="AR" className="image" src={property.find(id => id.id === "A").image}/>
                    <p className="value">{Number(params.row.AR).toFixed(1)} %</p>
                </div>
              );
            }},
            { field: "S", headerName: relicTranslation.speed[languageContext.language], sortable: false, filterable: false, minWidth: 80, maxWidth: 80,
          renderCell: (params) => {
            return (
              <div key={Math.random() + params.row.S} className="property" style={{display: "flex"}}>
                  <img title={property.filter(id => id.id === "S")[0].translation[languageContext.language]} alt="S" className="image" src={property.find(id => id.id === "S").image}/>
                  <p className="value">{params.row.S}</p>
              </div>
            );
          }},
                { field: "BE", headerName: relicTranslation.break_effect[languageContext.language], sortable: false, filterable: false, minWidth: 120, maxWidth: 120,
              renderCell: (params) => {
                return (
                  <div key={Math.random() + params.row.BE} className="property" style={{display: "flex"}}>
                      <img title={property.filter(id => id.id === "BE")[0].translation[languageContext.language]} alt="BE" className="image" src={property.find(id => id.id === "BE").image}/>
                      <p className="value">{Number(params.row.BE).toFixed(1)} %</p>
                  </div>
                );
              }},
              { field: "EH", headerName: relicTranslation.effect_hit_rate[languageContext.language], sortable: false, filterable: false, minWidth: 130, maxWidth: 130,
            renderCell: (params) => {
              return (
                <div key={Math.random() + params.row.EH} className="property" style={{display: "flex"}}>
                    <img title={property.filter(id => id.id === "EH")[0].translation[languageContext.language]} alt="EH" className="image" src={property.find(id => id.id === "EH").image}/>
                    <p className="value">{Number(params.row.EH).toFixed(1)} %</p>
                </div>
              );
            }},
              { field: "ER", headerName: relicTranslation.effect_resistance[languageContext.language], sortable: false, filterable: false,
            renderCell: (params) => {
              return (
                <div key={Math.random() + params.row.ER} className="property" style={{display: "flex"}}>
                    <img title={property.filter(id => id.id === "ER")[0].translation[languageContext.language]} alt="ER" className="image" src={property.find(id => id.id === "ER").image}/>
                    <p className="value">{Number(params.row.ER).toFixed(1)} %</p>
                </div>
              );
            }},
              { field: "A", headerName: relicTranslation.attack[languageContext.language], sortable: false, filterable: false, minWidth: 70, maxWidth: 70,
            renderCell: (params) => {
              return (
                <div key={Math.random() + params.row.A} className="property" style={{display: "flex"}}>
                    <img title={property.filter(id => id.id === "A")[0].translation[languageContext.language]} alt="A" className="image" src={property.find(id => id.id === "A").image}/>
                    <p className="value">{params.row.A}</p>
                </div>
              );
            }},
                { field: "D", headerName: relicTranslation.defend[languageContext.language], sortable: false, filterable: false, minWidth: 75, maxWidth: 75,
              renderCell: (params) => {
                return (
                  <div key={Math.random() + params.row.D} className="property" style={{display: "flex"}}>
                      <img title={property.filter(id => id.id === "D")[0].translation[languageContext.language]} alt="D" className="image" src={property.find(id => id.id === "D").image}/>
                      <p className="value">{params.row.D}</p>
                  </div>
                );
              }},
                { field: "DR", headerName: relicTranslation.defend_rate[languageContext.language], sortable: false, filterable: false, minWidth: 100, maxWidth: 100,
              renderCell: (params) => {
                return (
                  <div key={Math.random() + params.row.DR} className="property" style={{display: "flex"}}>
                      <img title={property.filter(id => id.id === "D")[0].translation[languageContext.language]} alt="DR" className="image" src={property.find(id => id.id === "D").image}/>
                      <p className="value">{Number(params.row.DR).toFixed(1)} %</p>
                  </div>
                );
              }},
                { field: "H", headerName: relicTranslation.hp[languageContext.language], sortable: false, filterable: false, minWidth: 80, maxWidth: 80,
              renderCell: (params) => {
                return (
                  <div key={Math.random() + params.row.H} className="property" style={{display: "flex"}}>
                      <img title={property.filter(id => id.id === "H")[0].translation[languageContext.language]} alt="HP" className="image" src={property.find(id => id.id === "H").image}/>
                      <p className="value">{params.row.H}</p>
                  </div>
                );
              }},
                { field: "HR", headerName: relicTranslation.hp_rate[languageContext.language], sortable: false, filterable: false, minWidth: 95, maxWidth: 95,
              renderCell: (params) => {
                return (
                  <div key={Math.random() + params.row.HR} className="property" style={{display: "flex"}}>
                      <img title={property.filter(id => id.id === "H")[0].translation[languageContext.language]} alt="HP" className="image" src={property.find(id => id.id === "H").image}/>
                      <p className="value">{Number(params.row.HR).toFixed(1)} %</p>
                  </div>
                );
              }},
              ]
            );
            break;
        case "profiles":
          tempInfo = {
            pageSize: 10,
            title: "",
            addNewButton: "block",
            addNewLink: "new",
            exportButton: "block"
          };

          var profileTranslation ={
            "region": {
              "zh-cn": "区域",
              "zh-tw": "區域",
              "en-us": "Region",
            },
            "name": {
              "zh-cn": "名字",
              "zh-tw": "名字",
              "en-us": "Name"
            },
            "world": {
              "zh-cn": "世界",
              "zh-tw": "世界",
              "en-us": "World"
            },
            "achievement": {
              "zh-cn": "成就",
              "zh-tw": "成就",
              "en-us": "Achievements"
            },
            "character": {
              "zh-cn": "角色数量",
              "zh-tw": "角色數量",
              "en-us": "Characters"
            },
            "chaos": {
              "zh-cn": "混沌回忆",
              "zh-tw": "混沌回憶",
              "en-us": "Chaos"
            },
            "updated_at": {
              "zh-cn": "更新于",
              "zh-tw": "更新於",
              "en-us": "Last Updated At"
            }
          }

          setFinalUserRows(overrideDataRow);
          setUserColumns(
            [
              { field: "no", headerName: "#", maxWidth: 60, disableColumnMenu: true},
              { field: "name", headerName: profileTranslation.name[languageContext.language], minWidth: 120},
              { field: "AchievementCount", headerName: profileTranslation.achievement[languageContext.language], minWidth: 140, disableColumnMenu: true,
              renderCell: (params) => {
                return (
                  <div className="achievement">
                    <p className="value">{params.row.AchievementCount}</p>
                        {
                        params.row.AchievementCount > 0 ?
                        <img alt="trophy" className="trophy" src={trophyIcon}/>
                        : <p></p>
                        }
                  </div>
                );
              }},
              /*
              { field: "Chaos", headerName: profileTranslation.chaos[languageContext.language], disableColumnMenu: true,
              renderCell: (params) => {
                return (
                  <div key={Math.random() + params.row.Chaos}>
                        <div className="chaos">

                        <p className="value">{params.row.Chaos}</p>
                        {
                        params.row.Chaos > 0 ?
                          <img alt="memoryOfChaos" className="memoryOfChaos" src={memoryOfChaosIcon}/>
                        : <p></p>
                        }
                        </div>
                  </div>
                );
              }},
              */
              { field: "region", headerName: profileTranslation.region[languageContext.language] , minWidth: 110},
              { field: "level", headerName: "LV", maxWidth: 90},
              { field: "world", headerName: profileTranslation.world[languageContext.language], minWidth: 80},
              { field: "AvatarCount", headerName: profileTranslation.character[languageContext.language], minWidth: 140},
              
              {
                field: "updated_at", headerName: profileTranslation.updated_at[languageContext.language], type: 'string', minWidth: 160, flex: 1,
                renderCell: (params) => {
                  return (
                    <p className="date">
                      {
                        new Date(params.row.updated_at.seconds * 1000).toLocaleString('en-US',
                        {
                          year: 'numeric', month: 'short', day: 'numeric',
                          hour: '2-digit', minute: '2-digit', hourCycle: 'h23'
                        })
                      }
                    </p>
                  );
                }
              },
            ]
          );
          break;
        default:
          break;
      }
      setRandomSeedRanking(Math.random());
      setInfo(tempInfo);
    };

    try{
      processData();
    }catch (e) {
          alert("We've encountered some errors.\nPlease try again later.");
          const ref = collection(db, "error");
          addDoc(ref, {
            code: "Datatable : Process Data",
            error: e.message,
            created_at: new Date(),
          });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderStatus, orderCreatedDate, overrideDataRow, languageContext]);

  return (
    <div className="datatable" key={randomSeedRanking}> 
      {
        typeof characterId !== "undefined" && typeof characterId.sortModel !== "undefined" ?
        <DataGrid
          className="datagrid"
          getRowHeight={() => 'auto'}
          initialState={{
            pagination: { paginationModel: { pageSize: info.pageSize } },
            sorting: {
              sortModel: characterId.sortModel
            }
          }}
          pageSizeOptions={[10, 50, 100]}
          rows={finalUserRows}
          columns={userColumns}
          checkboxSelection={info.checkboxSelection}
          onSelectionModelChange={(newSelectionModel) => {
            setSelectedRows(newSelectionModel);
          }}
          selectionModel={selectedRows}
        />
        :
        <DataGrid
          className="datagrid"
          getRowHeight={() => 'auto'}
          initialState={{
            pagination: { paginationModel: { pageSize: info.pageSize } }, 
            sorting: {
              sortModel: sortModel
            },
          }}
          pageSizeOptions={[10, 50, 100]}
          rows={finalUserRows}
          columns={userColumns}
          checkboxSelection={info.checkboxSelection}
          onSelectionModelChange={(newSelectionModel) => {
            setSelectedRows(newSelectionModel);
          }}
          selectionModel={selectedRows}
        />
      }
    </div>
  )
}

export default Datatable;