import { Link } from "react-router-dom";
import "./Footer.scss";
import { LanguageContext } from "../../App";
import { useContext } from "react";

const Footer = () => {
    const languageContext = useContext(LanguageContext);
    const language = {
        "disclaimer":{
          "en-us": "DISCLAIMER",
          "zh-cn": "免责声明",
          "zh-tw": "免責聲明"
        },
      }

    return (
        <div className="footer" style={{height: "50px"}}>
            <div className="sections">
                <img className="logo"
                    src={require('../image/logo_icon.png')}
                    alt=""
                />
            </div>
            <div className="sections">
                <Link to={"/" + languageContext.language + "/disclaimer"} style={{ textDecoration: "none"}}>
                    <h2>{language.disclaimer[languageContext.language]}</h2>
                </Link>
            </div>
        </div>
    )
}

export default Footer;