import { initializeApp } from "firebase/app";
import { getFirestore } from "@firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyAEEx_ynyQzU-DrsXkmarw79eFnmeU50hY",
  authDomain: "hsr-statistics.firebaseapp.com",
  projectId: "hsr-statistics",
  storageBucket: "hsr-statistics.appspot.com",
  messagingSenderId: "225058942275",
  appId: "1:225058942275:web:c5d8a32e9a0865701113f6",
  measurementId: "G-QNTCKZVHTD"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const db = getFirestore();
export const firestore = getFirestore(app);
