import { useContext } from "react";
import { LanguageContext } from "../../App";
import Footer from "../../components/footer/footer";
import Navbar from "../../components/navbar/navbar";
import "./Disclaimer.scss";
import { Helmet } from "react-helmet-async";

const Disclaimer = () => {
    const languageContext = useContext(LanguageContext);
    const language = {
        "disclaimer":{
          "en-us": "DISCLAIMER",
          "zh-cn": "免责声明",
          "zh-tw": "免責聲明",
        },
        "acknowledgment":{
          "en-us": "Appreciation and Acknowledgment",
          "zh-cn": "感谢与认可",
          "zh-tw": "感謝與認可",
        },
        "acknowledgment_1":{
          "en-us": "We appreciate and acknowledge that all rights to the original designs of Honkai: Star Rail belong to miHoYo, COGNOSPHERE PTE. LTD.",
          "zh-cn": "我们感谢并认可《崩坏：星穹铁道》原创设计的所有权利均属于 miHoYo, COGNOSPHERE PTE. 有限公司。",
          "zh-tw": "我們感謝並認可《崩壞：星穹鐵道》原創設計的所有權利均屬於 miHoYo, COGNOSPHERE PTE. 有限公司。",
        },
        "acknowledgment_2":{
          "en-us": "We appreciate and acknowledge that all player's public data are obtained via API of ",
          "zh-cn": "我们感谢并认可所有玩家的公开数据来源是 ",
          "zh-tw": "我們感謝並認可所有玩家的公開數據來源是",
        },
        "acknowledgment_3":{
          "en-us": ".",
          "zh-cn": " 的API。",
          "zh-tw": " 的API。",
        },
        "acknowledgment_4":{
          "en-us": "We appreciate and acknowledge that player has the right to remove their profile from our web app. Please contact us at ",
          "zh-cn": "我们感谢并认可玩家有权从我们的网络应用程序中删除他们的个人资料。 请在",
          "zh-tw": "我們感謝並認可玩家有權從我們的網絡應用程序中刪除他們的個人資料。請在",
        },
        "acknowledgment_5":{
            "en-us": "server.",
            "zh-cn": "伺服器通知我们。",
            "zh-tw": "伺服器通知我們。",
          },
        "collect":{
          "en-us": "Data Collection",
          "zh-cn": "数据采集",
          "zh-tw": "數據採集",
        },
        "collect_1":{
          "en-us": "We collect your public Honkai: Star Rail account information.",
          "zh-cn": "我们采集您的公开《崩坏：星穹铁道》帐户信息。",
          "zh-tw": "我們採集您的公開《崩壞：星穹鐵道》帳戶信息。",
        },
        "collect_2":{
          "en-us": "We collect your public browser, operating system, device information, session information when using HSR Statistics web app.",
          "zh-cn": "我们采集您使用 HSR统计 网络应用程序时的公共浏览器、操作系统、设备信息、会期信息。",
          "zh-tw": "我們採集您使用 HSR統計 網絡應用程序時的公共瀏覽器、操作系統、設備信息、會期信息。",
        },
        "do":{
          "en-us": "Data Usage",
          "zh-cn": "数据应用",
          "zh-tw": "數據應用",
        },
        "do_1":{
          "en-us": "We display player's PUBLIC data ONLY in an effective way.",
          "zh-cn": "我们以有效的方式展示玩家们的公开数据。",
          "zh-tw": "我們以有效的方式展示玩家們的公開數據。",
        },
        "do_2":{
          "en-us": "We use cache to store session information for providing better user experience.",
          "zh-cn": "我们使用缓存来存储会话信息以提供更好的用户体验。",
          "zh-tw": "我們使用緩存來存儲會話信息以提供更好的用戶體驗。",
        },
        "dont":{
          "en-us": "Forbidden Action",
          "zh-cn": "禁止行为",
          "zh-tw": "禁止行為",
        },
        "dont_1":{
          "en-us": "We're forbidden to collect any Passwords, Usernames and other Personal Information.",
          "zh-cn": "我们禁止收集任何密码、用户名和其他个人信息。",
          "zh-tw": "我們禁止收集任何密碼、用戶名和其他個人信息。",
        },
        "dont_2":{
          "en-us": "We're forbidden to contact you outside of the Website and ",
          "zh-cn": "我们禁止在网站和 ",
          "zh-tw": "我們禁止在網站和 ",
        },
        "dont_3":{
          "en-us": " Server.",
          "zh-cn": " 服务器之外与您联系。",
          "zh-tw": " 服務器之外與您聯繫。",
        },
        "cost":{
          "en-us": "Operation Cost",
          "zh-cn": "运营成本",
          "zh-tw": "運營成本",
        },
        "cost_1":{
          "en-us": "We displayed Advertisement on the website to sustain the operation cost.",
          "zh-cn": "我们在网站上展示广告以维持运营成本。",
          "zh-tw": "我們在網站上展示廣告以維持運營成本。",
        },
        "cost_2":{
          "en-us": "Be a ",
          "zh-cn": "加入 ",
          "zh-tw": "加入 ",
        },
        "cost_3":{
          "en-us": " and support our work!",
          "zh-cn": " 并且支持我们！",
          "zh-tw": " 並且支持我們！",
        },
      }

    return (
        <div className="disclaimerMain">
            <Helmet>
              <title>Disclaimer | HSR Statistics</title>
            </Helmet>
            <Navbar/>
            <div className="disclaimerBody">
                <h2>{language.disclaimer[languageContext.language]}</h2>
                <div className="sections">
                    <h3>{language.acknowledgment[languageContext.language]}</h3>
                    <ul>
                        <li>{language.acknowledgment_1[languageContext.language]}</li>
                        <li>{language.acknowledgment_2[languageContext.language]}<a href="https://api.mihomo.me/" target="_blank" rel="noreferrer">https://api.mihomo.me/</a>{language.acknowledgment_3[languageContext.language]}</li>
                        <li>{language.acknowledgment_4[languageContext.language]} <a href="https://discord.gg/aK6cJZ9hnQ" target="_blank" rel="noreferrer">Discord</a> {language.acknowledgment_5[languageContext.language]}</li>
                    </ul>
                </div>
                <div className="sections">
                    <h3>{language.collect[languageContext.language]}</h3>
                    <ul>
                        <li>{language.collect_1[languageContext.language]}</li>
                        <li>{language.collect_2[languageContext.language]}</li>
                    </ul>
                </div>
                
                <div className="sections">
                    <h3>{language.do[languageContext.language]}</h3>
                    <ul>
                        <li>{language.do_1[languageContext.language]}</li>
                        <li>{language.do_2[languageContext.language]}</li>
                    </ul>
                </div>
                <div className="sections">
                    <h3>{language.dont[languageContext.language]}</h3>
                    <ul>
                        <li>{language.dont_1[languageContext.language]}</li>
                        <li>{language.dont_2[languageContext.language]}<a href="https://discord.gg/aK6cJZ9hnQ" target="_blank" rel="noreferrer">Discord</a>{language.dont_3[languageContext.language]}</li>
                    </ul>
                </div>
                <div className="sections">
                    <h3>{language.cost[languageContext.language]}</h3>
                    <ul>
                        <li>{language.cost_1[languageContext.language]}</li>
                        <li>{language.cost_2[languageContext.language]}<a href="https://www.patreon.com/JackyTan" target="_blank" rel="noreferrer">Patreon</a>{language.cost_3[languageContext.language]}</li>
                    </ul>
                </div>
            </div>
            <Footer/>
        </div>
    )
}

export default Disclaimer;