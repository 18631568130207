import { useContext } from "react";
import { LanguageContext } from "../../App";
import Footer from "../../components/footer/footer";
import Navbar from "../../components/navbar/navbar";
import "./relicScorer.scss";
import "../index.scss";
import { Helmet } from "react-helmet-async";
import { useParams } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination } from "swiper/modules";
import { useState } from "react";
import { useEffect } from "react";
import { searchUID } from "../../components/utils";
import HorizontalDisplayAds from "../../components/ads/horizontalDisplayAds";

const RelicScorer = () => {
    const languageContext = useContext(LanguageContext);
    const params = useParams();
    const [search, setSearch] = useState("");
    const language = {
        title: {
          "zh-cn": "遗器计分器",
          "zh-tw": "遺器計分器",
          "en-us": "Relic Scorer",
        },
        subtitle: {
          "zh-cn": "遗器计分器可以依据各个角色有效的主词条、以及副词条去客观地评估遗器分数。",
          "zh-tw": "遗器计分器可以依据各个角色有效的主词条、以及副词条去客观地评估遗器分数。",
          "en-us": "The Relic Scorer objectively evaluates Relic Score for each characters based on their effective main stats and sub stats.",
        },
        preview: {
          "zh-cn": "成品预览",
          "zh-tw": "成品預覽",
          "en-us": "Result Preview",
        },
        portrait_view: {
          "zh-cn": "纵向视图",
          "zh-tw": "縱向視圖",
          "en-us": "Portrait View",
        },
        mobile_view: {
          "zh-cn": "手机视图",
          "zh-tw": "手機視圖",
          "en-us": "Mobile View",
        },
        search_uid: {
          "zh-cn": "查询 UID",
          "zh-tw": "查詢 UID",
          "en-us": "Search UID"
        },
        search_uid_subtitle: {
          "zh-cn": "有兴趣使用的话，在上面填写你《崩坏：星穹铁道》 的UID就可以了！<br>HSR Statistics 并不会向任何用户索取 UID 以外的任何资料。",
          "zh-tw": "有兴趣使用的话，在上面填写你《崩坏：星穹铁道》 的UID就可以了！<br>HSR Statistics 並不會向任何用戶索取 UID 以外的任何資料。",
          "en-us": "If you are interested in using it, just fill in your UID of Honkai: Star Rail above! <br>HSR Statistics will not ask any user for any information other than UID.",
        },
        example: {
          "zh-cn": "例子: 805714751",
          "zh-tw": "例子: 805714751",
          "en-us": "Example: 805714751",
        },
        score: {
          "zh-cn": "分数",
          "zh-tw": "分數",
          "en-us": "Score",
        },
        grade: {
          "zh-cn": "级别",
          "zh-tw": "級別",
          "en-us": "Grade",
        },
        formula_title: {
          "zh-cn": "公式算法",
          "zh-tw": "公式算法",
          "en-us": "Formula Algorithm",
        },
        formula_substat: {
          "zh-cn": "副词条：属性值 x 倍率 - 比如：50 攻击力 x 0.04 = 2分",
          "zh-tw": "副詞條：屬性值 x 倍率 - 比如：50 攻擊力 x 0.04 = 2分",
          "en-us":
            "Substat: Property Value x Rate - Example: 50 ATK x 0.04 = 2 Score",
        },
        formula_mainstat: {
          "zh-cn":
            "主词条：（帽子，手套，元素伤害，充能，治疗 1 / 其他部位有效词条倍率 1.6 / 其他部位无效词条倍率 0.2 ）x 遗器稀有度 x 遗器等级",
          "zh-tw":
            "主詞條：（帽子，手套，元素傷害，充能，治療 1 / 其他部位有效詞條倍率 1.6 / 其他部位無效詞條倍率 0.2 ）x 遺器稀有度 x 遺器等級",
          "en-us":
            "Main Stat: (Helmet, Glove, Elemental DMG, Energy Regeneration, Outgoing Heal 1 / Other Parts with Effective Stat 1.6 / Other Parts with Ineffective Stat 0.2 ) x Relic Rarity x Relic Level",
        },
        algorithm_update: {
          "zh-cn": "遗器分数算法更新于2023-09-20 3:07pm",
          "zh-tw": "遺器分數算法更新於2023-09-20 3:07pm",
          "en-us": "Relic score algorithm was updated on 2023-09-20 3:07pm",
        },
        main_stat_explanation_1: {
          "zh-cn": "主属性的分数上限分别为7.5以及12。<br><br>对于不会占据角色有效副词条的主属性，他们的最高评分是7.5。",
          "zh-tw": "主屬性的分數上限分別為7.5以及12。 <br><br>對於不會佔據角色有效副詞條的主屬性，他們的最高評分是7.5。",
          "en-us": "The score cap for the main stats are 7.5 and 12 respectively. <br><br>For main stats that do not take up effect sub stats for the character, their maximum rating is 7.5.",
        },
        main_stat_explanation_2: {
            "zh-cn": "<ol><li>帽子：生命值</li><li>手套：攻击力</li><li>衣服：治疗量加成</li><li>鞋子：-</li><li>位面球：7种属性伤害加成</li><li>连接绳：能量恢复效率</li></ol>",
            "zh-tw": "<ol><li>帽子：生命值</li><li>手套：攻擊力</li><li>衣服：治療量加成</li><li>鞋子：-</li><li>位面球：7種屬性傷害加成</li><li>連接繩：能量恢復效率</li></ol>",
            "en-us": "<ol><li>Helmet: HP</li><li>Glove: ATK</li><li>Body: Outgoing Healing Boost</li><li>Shoes: -</li><li>Planar Sphere: 7 Element DMG Boost</li><li>Link Rope: Energy Regeneration Rate</li></ol>",
          },
        main_stat_explanation_3: {
            "zh-cn": "除了上述的属性以外，对于角色无效的词条也是会被限制于7.5分。<br>我们用三月七作为例子。<br>暴击率，暴击伤害，攻击%等属性就属于无效词条。",
            "zh-tw": "除了上述的屬性以外，對於角色無效的詞條也是會被限制於7.5分。<br>我們用三月七作為例子。<br>暴擊率，暴擊傷害，攻擊%等屬性就屬於無效詞條。",
            "en-us": "In addition to the above Main Stats, entries that are ineffective for the character will also be capped at 7.5 points. <br>We use March 7th as an example here. <br>Main Stats such as Crit RATE, Crit DMG and ATK % are ineffective main stats.",
          },
      };

      const handleChange = (event) => {
        setSearch(event.target.value);
      };

      
  useEffect(() => {

    async function processUID()
    {
      if (isNaN(Number(search)) === false && search.length === 9) {
        await searchUID(search, true, params.language);

        setSearch("");
      }
  
      if (localStorage.getItem("profiles") !== "undefined") {
        var storedProfiles = JSON.parse(localStorage.getItem("profiles"));
        if (storedProfiles === null) storedProfiles = [];
  
        var cacheProfiles = [];
        for (let i = 0; i < storedProfiles.length; i++) {
          cacheProfiles.push({
            uid: storedProfiles[i].split(" ")[0],
            name: storedProfiles[i].split(" ")[1],
          });
        }
      }
    }
    processUID();
  }, [search]);

    return (
        <div className="relicScorerMain">
            <Helmet>
              <title>{language.title[params.language]} | HSR Statistics</title>
            <meta
              data-rh="true"
              property="og:title"
              content={language.subtitle[params.language] + " | HSR Statistics"}
            />
            <meta
              data-rh="true"
              property="description"
              content={language.subtitle[languageContext.language]}
            />
            <meta
              data-rh="true"
              property="og:description"
              content={language.subtitle[languageContext.language]}
            />
            <meta
              data-rh="true"
              property="twitter:description"
              content={language.subtitle[languageContext.language]}
            />
            </Helmet>
            <Navbar/>
            <div className="relicScorerBody starry-night">
              <div class="sub-container">
                <div class="sky">
                  <div class="stars"></div>
                  <div class="stars2"></div>
                  <div class="stars3"></div>
                </div>
              </div>
                <h1>{language.title[params.language]}</h1>
                <h2 className="subtitle">{language.subtitle[params.language]}</h2>
                <HorizontalDisplayAds/>
                <br></br>
                <div className="input">
                <h3>{language.search_uid[params.language]}</h3>
                <input
                    placeholder={language.example[params.language]}
                    value={search}
                    onChange={handleChange}
                ></input>
                
                <h4 dangerouslySetInnerHTML={{
                                __html: language.search_uid_subtitle[params.language],
                              }}
                              ></h4>
                </div>
                <br></br>
                <h3>{language.preview[params.language]}</h3>
                <div className="preview">
                    <div className="left">
                        <img className="image" alt="mobile_view" src={require("../../components/image/relic_scorer/mobile_view.webp")}/>
                        <h4>{language.mobile_view[params.language]}</h4>
                    </div>
                    <div className="right">
                        <img className="image" alt="portrait_view" src={require("../../components/image/relic_scorer/portrait_view.webp")}/>
                        <h4>{language.portrait_view[params.language]}</h4>
                    </div>
                </div>
          <div className="formula">
            <h2 className="formula-title">
              {language.formula_title[languageContext.language]}
            </h2>
            <h4>{language.formula_mainstat[languageContext.language]}</h4>
            <h4>{language.formula_substat[languageContext.language]}</h4>
            <div className="explanation">
                <img className="image" alt="helmet_score" src={require("../../components/image/relic_scorer/helmet_score.webp")}/>
                
                <div className="text">
                <h4 dangerouslySetInnerHTML={{
                                __html: language.main_stat_explanation_1[params.language],
                              }}
                              ></h4>
                              <h4 dangerouslySetInnerHTML={{
                                __html: language.main_stat_explanation_2[params.language],
                              }}
                              ></h4>
                              <h4 dangerouslySetInnerHTML={{
                                __html: language.main_stat_explanation_3[params.language],
                              }}
                              ></h4>
                </div>
            </div>
            <h4 className="remark">
              {language.algorithm_update[languageContext.language]}
            </h4>
            <div className="ranks">
              <div className="rank">
                <div className="rank-S+">S+</div>
                <div>
                  {" : " + language.score[languageContext.language] + " >= 52"}
                </div>
              </div>
              <div className="rank">
                <div className="rank-S">S</div>
                <div>
                  {" : " + language.score[languageContext.language] + " >= 42"}
                </div>
              </div>
              <div className="rank">
                <div className="rank-A">A</div>
                <div>
                  {" : " + language.score[languageContext.language] + " >= 32"}
                </div>
              </div>
              <div className="rank">
                <div className="rank-B">B</div>
                <div>
                  {" : " + language.score[languageContext.language] + " >= 25"}
                </div>
              </div>
              <div className="rank">
                <div className="rank-C">C</div>
                <div>
                  {" : " + language.score[languageContext.language] + " >= 20"}
                </div>
              </div>
              <div className="rank">
                <div className="rank-D">D</div>
                <div>
                  {" : " + language.score[languageContext.language] + " >= 15"}
                </div>
              </div>
              <div className="rank">
                <div className="rank-E">E</div>
                <div>
                  {" : " + language.score[languageContext.language] + " < 15"}
                </div>
              </div>
            </div>
          </div>
            </div>
            <Footer/>
        </div>
    )
}

export default RelicScorer;