import { useContext, useEffect, useState } from "react";
import Footer from "../../components/footer/footer";
import Navbar from "../../components/navbar/navbar";
import {
  query,
  orderBy,
  limit,
  getDoc,
  getDocs,
  startAfter,
  where,
  doc,
  collection,
} from "@firebase/firestore";
import { db } from "../../firebase";
import "./Relic.scss";
import "../index.scss";
import Datatable from "../../components/datatable/datatable";
import {
  relic,
} from "../../components/utils";
import { LanguageContext } from "../../App";
import { Helmet } from "react-helmet-async";
import { ArrowDropDown, ArrowDropUp } from "@mui/icons-material";
import HorizontalDisplayAds from "../../components/ads/horizontalDisplayAds";

const Relic = () => {

  const property = [
    {
      id: "cv",
      name: "CV",
      translation: {
        "en-us": "Relic Crit Ratio",
        "zh-cn": "遗器暴击比率",
        "zh-tw": "遺器暴擊比率",
      },
    },
    {
      id: "A",
      name: "ATK",
      translation: {
        "en-us": "ATK",
        "zh-cn": "攻击力",
        "zh-tw": "攻擊力",
      },
    },
    {
      id: "AR",
      name: "ATT",
      translation: {
        "en-us": "ATK %",
        "zh-cn": "攻击力 %",
        "zh-tw": "攻擊力 %",
      },
    },
    {
      id: "BE",
      name: "Break Effect",
      translation: {
        "en-us": "Break Effect",
        "zh-cn": "击破特攻",
        "zh-tw": "擊破特攻",
      },
    },
    {
      id: "CR",
      name: "CRIT Rate",
      translation: {
        "en-us": "CRIT Rate",
        "zh-cn": "暴击率",
        "zh-tw": "暴擊率",
      },
    },
    {
      id: "CD",
      name: "CRIT DMG",
      translation: {
        "en-us": "CRIT DMG",
        "zh-cn": "暴击伤害",
        "zh-tw": "暴擊傷害",
      },
    },
    {
      id: "D",
      name: "DEF",
      translation: {
        "en-us": "DEF",
        "zh-cn": "防御力",
        "zh-tw": "防禦力",
      },
    },
    {
      id: "DR",
      name: "DEF",
      translation: {
        "en-us": "DEF %",
        "zh-cn": "防御力 %",
        "zh-tw": "防禦力 %",
      },
    },
    {
      id: "EH",
      name: "Effect Hit Rate",
      translation: {
        "en-us": "Effect Hit Rate",
        "zh-cn": "效果命中",
        "zh-tw": "效果命中",
      },
    },
    {
      id: "ER",
      name: "Effect RES",
      translation: {
        "en-us": "Effect RES",
        "zh-cn": "效果抵抗",
        "zh-tw": "效果抵抗",
      },
    },
    {
      id: "H",
      name: "HP",
      translation: {
        "en-us": "HP",
        "zh-cn": "生命值",
        "zh-tw": "生命值",
      },
    },
    {
      id: "HR",
      name: "HP",
      translation: {
        "en-us": "HP %",
        "zh-cn": "生命值 %",
        "zh-tw": "生命值 %",
      },
    },
    {
      id: "S",
      name: "SPD",
      translation: {
        "en-us": "SPD",
        "zh-cn": "速度",
        "zh-tw": "速度",
      },
    },
  ];

  const firstRelic = relic.filter((r) => r.id.includes("_0"));
  const [filter, setFilter] = useState("all");
  const [selectedRelicSet, setSelectedRelicSet] = useState("");
  const [selectedRelicPart, setSelectedRelicPart] = useState("");
  const [selectedProperty, setSelectedProperty] = useState(property[0]);

  const [lastRelic, setLastRelic] = useState("");
  const [fullRelicList, setFullRelicList] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [page, setPage] = useState(0);

  const languageContext = useContext(LanguageContext);
  const language = {
    set: {
      "en-us": " Set",
      "zh-cn": " 件套",
      "zh-tw": " 件套",
    },
    search: {
      "en-us": "Relic Name",
      "zh-cn": "遗器名称",
      "zh-tw": "遺器名稱",
    },
    title: {
      "en-us": "Relic Ranking",
      "zh-cn": "遗器排行榜",
      "zh-tw": "遺器排行榜",
    },
    subtitle: {
      "en-us": "Evaluate the strength of Relic based on the Main Property and Critical Value",
      "zh-cn": "依据遗器具主词条以及双爆属性来评估强度",
      "zh-tw": "依據遺器具主詞條以及雙爆屬性來評估強度",
    },
    filter: {
      "en-us": "Relic Filter",
      "zh-cn": "遗器筛选",
      "zh-tw": "遺器篩選",
    },
    filter_property: {
      "en-us": "Filter Property",
      "zh-cn": "属性筛选",
      "zh-tw": "屬性篩選",
    },
    all: {
      "en-us": "All",
      "zh-cn": "全部",
      "zh-tw": "全部",
    },
    cavern_relics: {
      "en-us": "Cavern Relics",
      "zh-cn": "隧洞遗器",
      "zh-tw": "隧洞遺器",
    },
    planar_ornaments: {
      "en-us": "Planar Ornaments",
      "zh-cn": "位面饰品",
      "zh-tw": "位面飾品",
    },
    part: {
      "en-us": "Part",
      "zh-cn": "部位",
      "zh-tw": "部位",
    },
    property: {
      "en-us": "Property",
      "zh-cn": "属性",
      "zh-tw": "屬性",
    },
    sorting: {
      "en-us": "Sorting",
      "zh-cn": "排序",
      "zh-tw": "排序",
    },
  };

  async function getRelicsCount(item_id) {
    var docRef = doc(db, "count", "relic");
    if (item_id !== "") {
      docRef = doc(db, "count", item_id);
    }

    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      setTotalCount(docSnap.data().count);
    } else {
      setTotalCount(0);
    }
  }

  async function getRelics(nextPage, item_id) {
    var relicQuery = query(
      collection(db, "relic"),
      orderBy(selectedProperty.id, "desc"),
      limit(10)
    );

    if (nextPage === true) {
      relicQuery = query(
        collection(db, "relic"),
        orderBy("cv", "desc"),
        startAfter(lastRelic),
        limit(10)
      );

      if (item_id !== "") {
        relicQuery = query(
          collection(db, "relic"),
          where("relic_id", "==", item_id),
          orderBy(selectedProperty.id,  "desc"),
          startAfter(lastRelic),
          limit(10)
        );
      }
    } else {
      if (item_id !== "") {
        relicQuery = query(
          collection(db, "relic"),
          where("relic_id", "==", item_id),
          orderBy(selectedProperty.id,  "desc"),
          limit(10)
        );
      }
    }

    if (nextPage === true) {
      var currentPage = page;
      currentPage = currentPage + 1;
      setPage(currentPage);
      if (
        (currentPage + 1) * 10 > fullRelicList.length &&
        fullRelicList.length < totalCount
      ) {
        const documentSnapshots = await getDocs(relicQuery);
        var relics = [];
        documentSnapshots.forEach((doc) => {
          var relic = doc.data();
          relic.no = fullRelicList.length + relics.length + 1;
          relic.id = fullRelicList.length + relics.length + 1;

          if (Number(relic.main_property_value) < 1) {
            relic.main_property_percentage = true;
            relic.main_property_value = Number(
              (Math.floor(relic.main_property_value * 1000) / 10).toFixed(1)
            );
          } else {
            relic.main_property_percentage = false;
            relic.main_property_value = Number(
              Math.floor(relic.main_property_value).toFixed(0)
            );
          }

          relics.push(relic);
          setFullRelicList((oldArray) => [...oldArray, relic]);
          setLastRelic(doc);
        });
      }
    } else {
      const documentSnapshots = await getDocs(relicQuery);
      var relics = [];
      documentSnapshots.forEach((doc) => {
        var relic = doc.data();
        relic.no = relics.length + 1;
        relic.id = relics.length + 1;

        if (Number(relic.main_property_value) < 1) {
          relic.main_property_percentage = true;
          relic.main_property_value = Number(
            (Math.floor(relic.main_property_value * 1000) / 10).toFixed(1)
          );
        } else {
          relic.main_property_percentage = false;
          relic.main_property_value = Number(
            Math.floor(relic.main_property_value).toFixed(0)
          );
        }

        relics.push(relic);
        setLastRelic(doc);
      });
      setPage(0);
      setFullRelicList(relics);
    }
  }

  useEffect(() => {
    if (filter === "all") {
      getRelicsCount("");
      getRelics(false, "");
    }
  }, [filter]);

  useEffect(() => {
    if (selectedRelicPart !== "") {
      getRelics(false, selectedRelicPart);
    }
    else{
      getRelicsCount("");
      getRelics(false, "");
    }
  }, [selectedProperty]);

  function openMenu(property_menu, visibility) {
    var oppositeVisibility = "collapse";
    if (visibility === "collapse") oppositeVisibility = "visible";

    const mainMenu = document.getElementById(property_menu);
    mainMenu.style.visibility = visibility;

    const upArrow = document.getElementById(property_menu + "_up");
    upArrow.style.visibility = visibility;

    const downArrow = document.getElementById(property_menu + "_down");
    downArrow.style.visibility = oppositeVisibility;
  }

  return (
    <div className="relic">
      <Helmet>
        <title>{language.title[languageContext.language] + " | HSR Statistics"}</title>
            <meta
              data-rh="true"
              property="og:title"
              content={language.title[languageContext.language] + " | HSR Statistics"}
            />
            <meta
              data-rh="true"
              property="description"
              content={language.subtitle[languageContext.language]}
            />
            <meta
              data-rh="true"
              property="og:description"
              content={language.subtitle[languageContext.language]}
            />
            <meta
              data-rh="true"
              property="twitter:description"
              content={language.subtitle[languageContext.language]}
            />
      </Helmet>
      <Navbar />
      <div className="relic-body starry-night">
        <div class="sub-container">
                  <div class="sky">
                    <div class="stars"></div>
                    <div class="stars2"></div>
                    <div class="stars3"></div>
                  </div>
                </div>
        <div className="sections">
          
          <h1 className="title">{language.title[languageContext.language]}</h1>
          <h2 className="subtitle">{language.subtitle[languageContext.language]}</h2>
          <div className="rowContainer">
            <h3>{language.filter[languageContext.language]}</h3>
            <div className="row">
              <div
                className={
                  filter === "all"
                    ? "rowItems filter active"
                    : "rowItems filter"
                }
                key={"all"}
                onClick={() => {
                  setFullRelicList([]);
                  getRelicsCount("");
                  setFilter("all");
                  setSelectedRelicSet("");
                  setSelectedRelicPart("");
                }}
              >
                <h1>{language.all[languageContext.language]}</h1>
              </div>
              <div
                className={
                  filter === "cavern_relics"
                    ? "rowItems filter active"
                    : "rowItems filter"
                }
                key={"cavern_relics"}
                onClick={() => {
                  setFullRelicList([]);
                  setFilter("cavern_relics");
                  setPage(0);
                  getRelicsCount("101_0");
                  setSelectedRelicSet("Passerby of Wandering Cloud");
                  setSelectedRelicPart("101_0");
                  getRelics(false, "101_0");
                }}
              >
                <img src={relic.filter((rel) => rel.id === "102_0")[0].image} />
                <h4>{language.cavern_relics[languageContext.language]}</h4>
              </div>
              <div
                className={
                  filter === "planar_ornaments"
                    ? "rowItems filter active"
                    : "rowItems filter"
                }
                key={"planar_ornaments"}
                onClick={() => {
                  setFullRelicList([]);
                  setFilter("planar_ornaments");
                  setPage(0);
                  getRelicsCount("301_0");
                  setSelectedRelicSet("Space Sealing Station");
                  setSelectedRelicPart("301_0");
                  getRelics(false, "301_0");
                }}
              >
                <img src={relic.filter((rel) => rel.id === "301_0")[0].image} />
                <h4>{language.planar_ornaments[languageContext.language]}</h4>
              </div>
            </div>
          </div>
          {filter === "all" ? (
            <div></div>
          ) : filter === "cavern_relics" ? (
            <div className="rowContainer">
              <h3>{language.cavern_relics[languageContext.language]}</h3>
              <div className="row">
                {firstRelic
                  .filter((id) => id.type === "Cavern Relics")
                  .map((item, i) => {
                    return (
                      <div
                        key={item.id}
                        className={
                          selectedRelicSet === item.set
                            ? "rowItems relic-set active"
                            : "rowItems relic-set"
                        }
                        onClick={() => {
                          setFullRelicList([]);
                          setPage(0);
                          getRelicsCount(item.id);
                          setSelectedRelicSet(item.set);
                          setSelectedRelicPart(item.id);
                          getRelics(false, item.id);
                        }}
                      >
                        <img alt={item.element} src={item.image} />
                        <h4>
                          {item.translation_set[languageContext.language]}
                        </h4>
                      </div>
                    );
                  })}
              </div>
              <h3>{language.part[languageContext.language]}</h3>
              <div className="row">
                {relic
                  .filter((id) => id.set === selectedRelicSet)
                  .map((item, i) => {
                    return (
                      <div
                        key={item.id}
                        className={
                          selectedRelicPart === item.id
                            ? "rowItems relic-set active"
                            : "rowItems relic-set"
                        }
                        onClick={() => {
                          setFullRelicList([]);
                          getRelicsCount(item.id);
                          setSelectedRelicPart(item.id);
                          getRelics(false, item.id);
                        }}
                      >
                        <img alt={item.element} src={item.image} />
                        <h4>{item.translation[languageContext.language]}</h4>
                      </div>
                    );
                  })}
              </div>
            </div>
          ) : filter === "planar_ornaments" ? (
            <div className="rowContainer">
              <h3>{language.planar_ornaments[languageContext.language]}</h3>
              <div className="row">
                {firstRelic
                  .filter((id) => id.type === "Planar Ornaments")
                  .map((item, i) => {
                    return (
                      <div
                        key={item.id}
                        className={
                          selectedRelicSet === item.set
                            ? "rowItems relic-set active"
                            : "rowItems relic-set"
                        }
                        onClick={() => {
                          setFullRelicList([]);
                          setPage(0);
                          getRelicsCount(item.id);
                          setSelectedRelicSet(item.set);
                          setSelectedRelicPart(item.id);
                          getRelics(false, item.id);
                        }}
                      >
                        <img alt={item.element} src={item.image} />
                        <h4>
                          {item.translation_set[languageContext.language]}
                        </h4>
                      </div>
                    );
                  })}
              </div>
              <h3>{language.part[languageContext.language]}</h3>
              <div className="row">
                {relic
                  .filter((id) => id.set === selectedRelicSet)
                  .map((item, i) => {
                    return (
                      <div
                        key={item.id}
                        className={
                          selectedRelicPart === item.id
                            ? "rowItems relic-set active"
                            : "rowItems relic-set"
                        }
                        onClick={() => {
                          setFullRelicList([]);
                          getRelicsCount(item.id);
                          setSelectedRelicPart(item.id);
                          getRelics(false, item.id);
                        }}
                      >
                        <img alt={item.element} src={item.image} />
                        <h4>{item.translation[languageContext.language]}</h4>
                      </div>
                    );
                  })}
              </div>
            </div>
          ) : (
            <div></div>
          )}
          
          <div className="rowContainer">
              <h3>{language.filter_property[languageContext.language]}</h3>
              <div className="row menuRow">
                <div
                  className="menu"
                  data-title={language.property[languageContext.language]}
                >
                  <div className="menuSelection">
                    <h4>
                      {selectedProperty.translation[languageContext.language]}
                    </h4>
                    <ArrowDropDown
                      className="arrow"
                      id="property_menu_down"
                      onClick={() => {
                        openMenu("property_menu", "visible");
                      }}
                    />
                    <ArrowDropUp
                      className="arrow"
                      id="property_menu_up"
                      style={{ visibility: "collapse" }}
                      onClick={() => {
                        openMenu("property_menu", "collapse");
                      }}
                    />
                  </div>
                  <ul className="mainmenu" id="property_menu">
                    {property.map((item, i) => {
                      return (
                        <li
                          key={item.id}
                          onClick={() => {
                            setFullRelicList([]);
                            openMenu("property_menu", "collapse");
                            setSelectedProperty(item);
                          }}
                        >
                          {item.translation[languageContext.language]}
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
            </div>
        </div>
          <HorizontalDisplayAds/>
        <div className="sections relic-section">
          <h3>{language.title[languageContext.language]}</h3>
          <Datatable
            type="relic"
            className="datatable"
            overrideDataRow={fullRelicList.slice(page * 10, (page + 1) * 10)}
            sortModel={[
              {
                field: selectedProperty.id,
                sort:  "desc",
              },
            ]}
          />
          <div className="dataTable-footer">
            <h4 className="pagination"
              onClick={() => {
                var currentPage = page;
                if (currentPage > 0) {
                  currentPage = currentPage - 1;
                  setPage(currentPage);
                }
              }}
            >
              {"<"}
            </h4>

            {(page + 1) * 10 > totalCount ? (
              <h4></h4>
            ) : totalCount > 10 ? (
              <h4 className="pagination"
                onClick={() => {
                  if(selectedRelicPart === "")
                  {
                    getRelics(true, "");
                  }
                  else{
                    getRelics(true, selectedRelicPart);
                  }
                }}
              >
                {">"}
              </h4>
            ) : (
              <h4></h4>
            )}
            {(page + 1) * 10 > totalCount ? (
              <h4>
                {1 + page * 10}–{totalCount} of {totalCount}
              </h4>
            ) : totalCount > 10 ? (
              <h4>
                {1 + page * 10}–{(page + 1) * 10} of {totalCount}
              </h4>
            ) : (
              <h4>1 – {totalCount}</h4>
            )}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Relic;
