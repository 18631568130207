import { useContext, useEffect, useState } from "react";
import Footer from "../../components/footer/footer";
import Navbar from "../../components/navbar/navbar";
import "../index.scss";
import "./Character.scss";
import {
  briefCharacterRows,
  path,
  elements,
  rarity,
} from "../../components/utils";
import { LanguageContext } from "../../App";
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router-dom";
import HorizontalDisplayAds from "../../components/ads/horizontalDisplayAds";
import { School } from "@mui/icons-material";
const Character = () => {

  const navigate = useNavigate();
  
  const languageContext = useContext(LanguageContext);
  const language = {
    "title":{
      "en-us": "Character List",
      "zh-cn": "角色列表",
      "zh-tw": "角色列表",
    },
    "subtitle":{
      "en-us": "Character Build Guide including Recommended Eidolon, Light Cone, Relics and Skills",
      "zh-cn": "角色推荐星魂、光锥、遗器、技能的养成攻略",
      "zh-tw": "角色推薦星魂、光錐、遺器、技能的養成攻略",
    },
    "search":{
      "en-us": "Search",
      "zh-cn": "查询",
      "zh-tw": "查詢",
    },
    "character_name":{
      "en-us": "Character Name",
      "zh-cn": "角色名称",
      "zh-tw": "角色名稱",
    },
    "rarity":{
      "en-us": "Rarity",
      "zh-cn": "稀有度",
      "zh-tw": "稀有度",
    },
    "elements":{
      "en-us": "Elements",
      "zh-cn": "元素",
      "zh-tw": "元素",
    },
    "path":{
      "en-us": "Path",
      "zh-cn": "命途",
      "zh-tw": "命途",
    },
    "upcoming_character_list":{
      "en-us": "Highlight Character List",
      "zh-cn": "重点角色列表",
      "zh-tw": "重點角色列表",
    },
  }

  const [modifiedCharacterRows, setModifiedCharacterRows] = useState([]);

  useEffect(() => {
    function processModifiedCharacterRows() {
      var modifiedRows = [];
      for (let i = 0; i < briefCharacterRows.length; i++) {
        switch (briefCharacterRows[i].id) {
          case "8001":
            var currentRow = briefCharacterRows[i];
            currentRow.id = "8001,8002";
            modifiedRows.push(currentRow);
            break;
          case "8002":
            break;
          case "8003":
            var currentRow = briefCharacterRows[i];
            currentRow.id = "8003,8004";
            modifiedRows.push(currentRow);
            break;
          case "8004":
            break;
          case "8005":
            var currentRow = briefCharacterRows[i];
            currentRow.id = "8005,8006";
            modifiedRows.push(currentRow);
            break;
          case "8006":
            break;
          default:
            modifiedRows.push(briefCharacterRows[i]);
        }
      }
      modifiedRows = modifiedRows.filter(id => id.type !== "upcoming");

      modifiedRows = modifiedRows.sort((a, b) =>
      a.name > b.name ? 1 : -1
      );
      
      
      setSelectedCharacters(modifiedRows);
      setModifiedCharacterRows(modifiedRows);
      setRandomSeedFilter(Math.random());
    }
    processModifiedCharacterRows();
  }, [briefCharacterRows]);

  const [search, setSearch] = useState("");
  const [selectedCharacters, setSelectedCharacters] = useState(
    modifiedCharacterRows
  );
  const [randomSeedFilter, setRandomSeedFilter] = useState(0);

  const [selectedId, setSelectedId] = useState(briefCharacterRows[0]);


  useEffect(() => {

    function filterCharacters()
    {
      if(modifiedCharacterRows.length === 0)
        return;

      var tempRows = modifiedCharacterRows;
      if(search !== "")
      {
        var filteredRows = tempRows;
        for(let i = 0; i < tempRows.length; i++)
        {
          if(String(tempRows[i].translation["en-us"]).toLocaleLowerCase()
          .includes(search.toLocaleLowerCase()) === false && 
          String(tempRows[i].translation["zh-cn"])
          .includes(search.toLocaleLowerCase()) === false && 
          String(tempRows[i].translation["zh-tw"])
          .includes(search.toLocaleLowerCase()) === false)
          {
            filteredRows = filteredRows.filter(id => id.id !== tempRows[i].id);
          }
        }
        setSelectedCharacters(filteredRows);
        return;
      }


      if(path.filter(id => id.checked === false).length !== path.length)
      {
        var truePath = path.filter(id => id.checked === true);
        var filteredRows = tempRows;
        for(let i = 0; i < tempRows.length; i++)
        {
          var contain = false;
          for(let j = 0; j < truePath.length; j++)
          {
            if(truePath[j].name === tempRows[i].path)
            {
              contain = true;
              break;
            }
          }

          if(contain === false)
          {
            filteredRows = filteredRows.filter(id => id.id !== tempRows[i].id);
          }
        }

        tempRows = filteredRows;
      }

      if(elements.filter(id => id.checked === false).length !== elements.length)
      {
        var trueElements = elements.filter(id => id.checked === true);
        var filteredRows = tempRows;
        for(let i = 0; i < tempRows.length; i++)
        {
          var contain = false;
          for(let j = 0; j < trueElements.length; j++)
          {
            if(trueElements[j].name === tempRows[i].element)
            {
              contain = true;
              break;
            }
          }

          if(contain === false)
          {
            filteredRows = filteredRows.filter(id => id.id !== tempRows[i].id);
          }
        }
        tempRows = filteredRows;
      }

      if(rarity.filter(id => id.checked === false).length !== rarity.length)
      {
        var trueRarity = rarity.filter(id => id.checked === true);
        var filteredRows = tempRows;
        for(let i = 0; i < tempRows.length; i++)
        {
          var contain = false;
          for(let j = 0; j < trueRarity.length; j++)
          {
            if(trueRarity[j].rarity_number === tempRows[i].rarity_number)
            {
              contain = true;
              break;
            }
          }

          if(contain === false)
          {
            filteredRows = filteredRows.filter(id => id.id !== tempRows[i].id);
          }
        }
        tempRows = filteredRows;
      }

      setSelectedCharacters(tempRows);
    }

    filterCharacters();
  }, [randomSeedFilter]);

  function handleElementCheckboxClick(name) {
    for (let i = 0; i < elements.length; i++) {
      if (elements[i].name === name) {
        elements[i].checked = !elements[i].checked;
        document.getElementById(name).checked = elements[i].checked;
        setRandomSeedFilter(Math.random());
        break;
      }
    }
  }

  function handleRarityCheckboxClick(name) {
    for (let i = 0; i < rarity.length; i++) {
      if (rarity[i].name === name) {
        rarity[i].checked = !rarity[i].checked;
        document.getElementById(name).checked = rarity[i].checked;
        setRandomSeedFilter(Math.random());
        break;
      }
    }
  }

  function handlePathCheckboxClick(name) {
    for (let i = 0; i < path.length; i++) {
      if (path[i].name === name) {
        path[i].checked = !path[i].checked;
        document.getElementById(name).checked = path[i].checked;
        setRandomSeedFilter(Math.random());
        break;
      }
    }
  }

  const handleChange = (event) => {
    setSearch(event.target.value);
    setRandomSeedFilter(Math.random());
  };

  return (
    <div className="character">
      <Helmet>
        <title>{language.title[languageContext.language] + " | HSR Statistics"}</title>
            <meta
              data-rh="true"
              property="og:title"
              content={language.title[languageContext.language] + " | HSR Statistics"}
            />
            <meta
              data-rh="true"
              property="description"
              content={language.subtitle[languageContext.language]}
            />
            <meta
              data-rh="true"
              property="og:description"
              content={language.subtitle[languageContext.language]}
            />
            <meta
              data-rh="true"
              property="twitter:description"
              content={language.subtitle[languageContext.language]}
            />
      </Helmet>
      <Navbar />
      <div className="characterBody starry-night">
        <div class="sub-container">
            <div class="sky">
              <div class="stars"></div>
              <div class="stars2"></div>
              <div class="stars3"></div>
            </div>
          </div>
        <div className="sections">
          <h1 className="title">{language.title[languageContext.language]}</h1>
          <h2 className="subtitle">{language.subtitle[languageContext.language]}</h2>

          <div className="rowContainer">
            <h3>{language.search[languageContext.language]}</h3>
            <div className="row">
              <input
                placeholder={language.character_name[languageContext.language]}
                value={search}
                onChange={handleChange}
              ></input>
            </div>
          </div>
          <div className="rowContainer">
            <h3>{language.rarity[languageContext.language]}</h3>
            <div className="row">
              {rarity.map((item, i) => {
                return (
                  <div key={item.name}>
                    <label className="rowItems" style={{ marginLeft: "10px" }}>
                      <input
                        className="rowItems"
                        id={item.name}
                        type="checkbox"
                        checked={item.checked}
                        onChange={(e) => {
                          handleRarityCheckboxClick(item.name);
                        }}
                      />
                      <img
                        className="rowItems"
                        alt={item.element}
                        src={item.image}
                      />
                      {item.translation[languageContext.language]}
                    </label>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="rowContainer">
            <h3>{language.elements[languageContext.language]}</h3>
            <div className="row">
              {elements.map((item, i) => {
                return (
                  <div key={item.name}>
                    <label className="rowItems" style={{ marginLeft: "10px" }}>
                      <input
                        className="rowItems"
                        id={item.name}
                        type="checkbox"
                        checked={item.checked}
                        onChange={(e) => {
                          handleElementCheckboxClick(item.name);
                        }}
                      />
                      <img
                        className="rowItems"
                        alt={item.element}
                        src={item.image}
                      />
                      {item.translation[languageContext.language]}
                    </label>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="rowContainer">
            <h3>{language.path[languageContext.language]}</h3>
            <div className="row">
              {path.map((item, i) => {
                return (
                  <div key={item.name}>
                    <label className="rowItems" style={{ marginLeft: "10px" }}>
                      <input
                        className="rowItems"
                        id={item.name}
                        type="checkbox"
                        checked={item.checked}
                        onChange={(e) => {
                          handlePathCheckboxClick(item.name);
                        }}
                      />
                      <img
                        className="rowItems"
                        alt={item.element}
                        src={item.image}
                      />
                      {item.translation[languageContext.language]}
                    </label>
                  </div>
                );
              })}
            </div>
          </div>
        </div>

        <HorizontalDisplayAds/>
        <div className="characters_container">
          <h2>{language.upcoming_character_list[languageContext.language]}</h2>
          <div className="characters_sections">
            {briefCharacterRows.filter(id => id.type === "upcoming" && id.guide === true).sort((a, b) =>
      a.name > b.name ? 1 : -1
      ).map((item, i) => {
              return (
                <div
                  key={item.id}
                  className={
                    item.id === selectedId.id
                      ? "characters active"
                      : "characters"
                  }
                  onClick={() => {
                    navigate("/" + languageContext.language + "/character/" + item.name);
                  }}
                >
                  <img
                    className="element"
                    alt={item.element}
                    src={item.elementIcon}
                  ></img>
                  <img
                    className="path"
                    alt={item.path}
                    src={item.pathIcon}
                  ></img>
                  {
                    item.guide === true ? <School className="school-icon"/> : <School className="school-icon" style={{display: "none"}}/>
                  }
                  <div>
                    <img
                      className={"rarity-" + item.rarity_number}
                      alt={item.rarity_number}
                      src={item.rarity}
                    />
                  </div>
                  <img className="image" alt={item.name} src={item.image}></img>
                  <h4 className="name">{item.translation[languageContext.language]}</h4>
                </div>
              );
            })}
          </div>
          <h2>{language.title[languageContext.language]}</h2>
          <div className="characters_sections">
            {selectedCharacters.filter(id => id.guide === true).map((item, i) => {
              return (
                <div
                  key={item.id}
                  className={
                    item.id === selectedId.id
                      ? "characters active"
                      : "characters"
                  }
                  onClick={() => {
                      navigate("/" + languageContext.language + "/character/" + item.name);
                  }}
                >
                  <img
                    className="element"
                    alt={item.element}
                    src={item.elementIcon}
                  ></img>
                  <img
                    className="path"
                    alt={item.path}
                    src={item.pathIcon}
                  ></img>
                  {
                    item.guide === true ? <School className="school-icon"/> : <School className="school-icon" style={{display: "none"}}/>
                  }
                  <div>
                    <img
                      className={"rarity-" + item.rarity_number}
                      alt={item.rarity_number}
                      src={item.rarity}
                    />
                  </div>
                  <img className="image" alt={item.name} src={item.image}></img>
                  <h4 className="name">{item.translation[languageContext.language]}</h4>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <Footer/>
    </div>
  );
};

export default Character;
