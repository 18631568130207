import React, { useEffect } from "react"

const HorizontalDisplayAds = () => {
  useEffect(() => {
  }, [])

  const premiumUsers = ['803964415', '600900400'];
  
  if (localStorage.getItem("profiles") !== "undefined" && localStorage.getItem("profiles") !== null) {
    var storedProfiles = JSON.parse(localStorage.getItem("profiles"));
    var premiumUser = false;
    for(let i = 0; i < storedProfiles.length; i++)
    {
      if(premiumUsers.includes(String(storedProfiles[i]).split(' ')[0]))
      {
        premiumUser = true;
      }
    }

    if(true)
    {
      return(<div></div>)
    }
    else
    {
      
      const pushAd = () => {
        try {
          const adsbygoogle = window.adsbygoogle;
          adsbygoogle.push({});
        } catch (e) {
          console.error(e);
        }
      }
  
      let interval = setInterval(() => {
        // Check if Adsense script is loaded every 300ms
        if (window.adsbygoogle) {
          pushAd()
          // clear the interval once the ad is pushed so that function isn't called indefinitely
          clearInterval(interval)
        }
      }, 300)

      return (
        
        <div className="horizontal-display-ads" align="center">
            <ins className="adsbygoogle horizontal-ads"
            style={{display:"block"}}
            data-ad-client="ca-pub-8613500118620270"
            data-ad-slot="1157856423"
            data-ad-format="auto"
            data-full-width-responsive="true"></ins>
        </div>
      )
    }
  }
  else
  {

    return (
    
      <div className="horizontal-display-ads" align="center">
          <ins className="adsbygoogle horizontal-ads"
          style={{display:"block"}}
          data-ad-client="ca-pub-8613500118620270"
          data-ad-slot="1157856423"
          data-ad-format="auto"
          data-full-width-responsive="true"></ins>
       </div>
    )
  }

}

export default HorizontalDisplayAds;