import { useEffect, useState } from "react";
import Footer from "../../components/footer/footer";
import Navbar from "../../components/navbar/navbar";
import {
  query,
  onSnapshot,
  addDoc,
  doc,
  collection,
  orderBy,
  limit,
  getDoc,
  getDocs,
  startAfter,
  where
} from "@firebase/firestore";
import { db } from "../../firebase";
import "../index.scss";
import "./Home.scss";
import Datatable from "../../components/datatable/datatable";
import { briefCharacterRows, convertArtifact, convertProperty, searchUID } from "../../components/utils";
import { LanguageContext } from "../../App";
import { useContext } from "react";
import { Autoplay, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Helmet } from "react-helmet-async";
import { useNavigate, useParams } from "react-router-dom";
import HorizontalDisplayAds from "../../components/ads/horizontalDisplayAds";
import { School } from "@mui/icons-material";
import { Tooltip, Dialog, Backdrop, CircularProgress } from "@mui/material";


const Home = () => {
  const navigate = useNavigate();
  const params = useParams();
  const languageContext = useContext(LanguageContext);
  var currentLanguage = "";
  
  switch(params.language)
  {
    case "en-us":
    case "zh-tw":
    case "zh-cn":
      currentLanguage = params.language;
      break;
    default:
      currentLanguage = "en-us";
      break;
  }

  const [search, setSearch] = useState("");
  const [profileRows, setProfileRows] = useState([]);
  const [randomSeed, setRandomSeed] = useState(0);
  const [processProfileRows, setProcessProfileRows] = useState([]);
  const [cacheProfileRows, setCacheProfileRows] = useState([]);
  const [searchedProfileVisibility, setSearchedProfileVisibility] = useState("none");
  const [fullProfileVisibility, setFullProfileVisibility] = useState("block");

  const [lastRelic, setLastRelic] = useState("");
  const [fullRelicList, setFullRelicList] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [page, setPage] = useState(0);

  const language = {
    title: {
      "en-us": "Build Guide, Ranking, Relic Scorer and Customized Character Card of Honkai: Star Rail",
      "zh-cn": "《崩坏：星穹铁道》的排行榜、攻略、遗器计算、自定义角色卡",
      "zh-tw": "《崩壞：星穹鐵道》的排行榜、攻略、遺器計算、自定義角色卡",
    },
    upcoming_character_list:{
      "en-us": "Highlight Character List",
      "zh-cn": "重点角色列表",
      "zh-tw": "重點角色列表",
    },
    ranking_list: {
      "en-us":
        "The ranking list below is sorted default by Achievements count.",
      "zh-cn": "下面的排行榜列表，是默认按成就的数量排序的。",
      "zh-tw": "下面的排行榜列表，是默認按成就的數量排序的。",
    },
    search_uid: {
      "en-us": "Search UID",
      "zh-cn": "查询 UID",
      "zh-tw": "查詢 UID",
    },
    searched_profiles: {
      "en-us": "Searched Profiles",
      "zh-cn": "已查询的玩家",
      "zh-tw": "查詢的玩家",
    },
    full_profiles: {
      "en-us": "Full Profiles",
      "zh-cn": "所有玩家",
      "zh-tw": "所有玩家",
    },
    example: {
      "en-us": "Example: 805714751",
      "zh-cn": "例子: 805714751",
      "zh-tw": "例子: 805714751",
    },
    added: {
      "en-us": " has been added into system.",
      "zh-cn": " 已添加到系统中。",
      "zh-tw": " 已添加到系統中。",
    },
    processing: {
      "en-us": "Processing ...",
      "zh-cn": "处理中 。。",
      "zh-tw": "處理中 。。",
    },
    features: {
      "en-us": "Feature Preview of HSR Statistics",
      "zh-cn": "HSR Statistics 的功能预览",
      "zh-tw": "HSR Statistics 的功能預覽",
    },
    profile_check: {
      "en-us": "Profile : Character Card and Relic Scorer with Image Customization",
      "zh-cn": "个人资料：具有图像定制功能的角色卡和遗物记分器",
      "zh-tw": "個人資料：具有圖像定制功能的角色卡和遺物記分器",
    },
    character_check: {
      "en-us": "Character : Allows player to check Character Strength, Guides and Ranking",
      "zh-cn": "角色：允许玩家检查角色强度、指南和排名",
      "zh-tw": "角色：允許玩家檢查角色強度、指南和排名",
    },
    relic_check: {
      "en-us": "Relic : Allows player to check Relic Strength and Ranking",
      "zh-cn": "遗器：允许玩家检查遗器强度和排名",
      "zh-tw": "遺器：允許玩家檢查遺器強度和排名",
    },
  };


  async function getRelics(nextPage, item_id) {
    var relicQuery = query(
      collection(db, "user_simplified"),
      orderBy("ac", "desc"),
      limit(10)
    );

    if (nextPage === true) {
      relicQuery = query(
        collection(db, "user_simplified"),
        orderBy("ac", "desc"),
        startAfter(lastRelic),
        limit(10)
      );
    }

    if (nextPage === true) {
      var currentPage = page;
      currentPage = currentPage + 1;

      setPage(currentPage);
      if (
        (currentPage + 1) * 10 > fullRelicList.length &&
        fullRelicList.length < totalCount
      ) {
        const documentSnapshots = await getDocs(relicQuery);
        documentSnapshots.forEach((doc) => {
          var relic = doc.data();
          var region = "";
          switch (relic.u.substring(0, 1)) {
            case "0":
              region = "miHoYo Internal";
              break;
            case "5":
              region = "CN-Alternative";
              break;
            case "2":
            case "1":
              region = "CN";
              break;
            case "6":
              region = "NA";
              break;
            case "7":
              region = "EU";
              break;
            case "8":
              region = "Asia";
              break;
            case "9":
              region = "THM";
              break;
            default:
              break;
          }

          relic.m = relic.c;
          if(Number(relic.m) > 10)
          {
            relic.m = 0;
          }

          setFullRelicList((oldArray) => [...oldArray, 
            {
              no: oldArray.length + 1,
              id: relic.u,
              region: region,
              uid: relic.u,
              world: relic.w,
              level: relic.l,
              name: relic.n,
              AchievementCount: relic.ac,
              AvatarCount: relic.cc,
              Memory: relic.m,
              Chaos: relic.m,
              updated_at: relic.d,
            }]);
          setLastRelic(doc);
        });
      }
    } else {
      var currentTime = new Date().getTime();
      var cacheTime = localStorage.getItem("profiles_ranking_time");

      // If the user refresh Profile Ranking within 5 minutes
      if (localStorage.getItem("profiles_ranking") !== "undefined" 
      && localStorage.getItem("profiles_ranking_time") !== "undefined"
      && JSON.parse(localStorage.getItem("profiles_ranking")) !== null 
      && localStorage.getItem("profiles_ranking_time") !== null
      && (currentTime - cacheTime) / 1000 <= 300) {
        var storedProfiles = JSON.parse(localStorage.getItem("profiles_ranking"));
        if (storedProfiles === null) 
          storedProfiles = [];

        setFullRelicList(storedProfiles);
        
      }
      else
      {
        const documentSnapshots = await getDocs(relicQuery);
        var currentCount = 0;
        var relics = [];
        documentSnapshots.forEach((doc) => {
          currentCount++;
          var relic = doc.data();
          var region = "";
          switch (relic.u.substring(0, 1)) {
            case "0":
              region = "miHoYo Internal";
              break;
            case "5":
              region = "CN-Alternative";
              break;
            case "2":
            case "1":
              region = "CN";
              break;
            case "6":
              region = "NA";
              break;
            case "7":
              region = "EU";
              break;
            case "8":
              region = "Asia";
              break;
            case "9":
              region = "THM";
              break;
            default:
              break;
          }
          
          relic.m = relic.c;
          if(Number(relic.m) > 10)
          {
            relic.m = 0;
          }
  
          relics.push(
            {
              no: fullRelicList.length + currentCount,
              id: relic.u,
              region: region,
              uid: relic.u,
              world: relic.w,
              level: relic.l,
              name: relic.n,
              AchievementCount: relic.ac,
              AvatarCount: relic.cc,
              Memory: relic.m,
              Chaos: relic.m,
              updated_at: relic.d,
            }
          );
          setLastRelic(doc);
        });
        
        
      localStorage.setItem("profiles_ranking_time", new Date().getTime());
        setPage(0);
        setFullRelicList(relics);
      }

    }
  }

  useEffect(() => {
    async function getRelicsCount() {
      var docRef = doc(db, "count", "user");
  
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        setTotalCount(docSnap.data().count);
      } else {
        setTotalCount(0);
      }
    }
    getRelicsCount();
    getRelics(false);

  },[]);

  useEffect(() => {

    if(fullRelicList.length > 0)
    {
      localStorage.setItem(
        "profiles_ranking",
        JSON.stringify(fullRelicList)
      );

      
    }
  },[fullRelicList]);
  const handleChange = (event) => {
    setSearch(event.target.value);
  };
  // Search Profile and Save into Firestore afterwards
  
  async function processUID()
  {
    if (isNaN(Number(search)) === false && search.length === 9) {
    
      //const loadingUI = document.getElementById("loadContainer");
      //loadingUI.style.visibility = "visible";
      //document.body.style.cursor = "progress";
      setOpenDialog(true);
      await searchUID(search, true, currentLanguage);
      setSearch("");
      setOpenDialog(false);

      //document.body.style.cursor = "default";
      //loadingUI.style.visibility = "hidden";
    }
  }

  useEffect(() => {

    processUID();
    

    if (localStorage.getItem("profiles") !== "undefined") {
      var storedProfiles = JSON.parse(localStorage.getItem("profiles"));
      if (storedProfiles === null) storedProfiles = [];

      var cacheProfiles = [];
      for (let i = 0; i < storedProfiles.length; i++) {
        cacheProfiles.push({
          uid: storedProfiles[i].split(" ")[0],
          name: storedProfiles[i].split(" ")[1],
        });
      }
    }
  },[search]);

  
  async function refreshDocuments()
  {
    // 1698224600 before
    // 1698224653
    let start = new Date(Number(1701792000000));
    console.log(new Date());
    const q = query(collection(db, "user_simplified"), where("d", "<", start), limit(10));

    // 600553254  
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      console.log("REFRESHING " + doc.id);
      searchUID(doc.id, false);
    });
  }
  /*
  // Retrieve Profiles
  useEffect(() => {
    try {
      retrieveProfiles();
    } catch (e) {
      alert("We've encountered some errors.\nPlease try again later.");
      const ref = collection(db, "error");
      addDoc(ref, {
        code: "Home : Retrieve Profiles",
        error: e.message,
        created_at: new Date(),
      });
    }
    async function retrieveProfiles() {
      var currentProfileRows = [];
      const userDocsCollection = collection(db, "report/home/profile");
      const userDocsQuery = query(userDocsCollection);
      const unsubscribe = onSnapshot(userDocsQuery, (snapshot) => {
        unsubscribe();
        currentProfileRows = Object.assign([], currentProfileRows);
        snapshot.docChanges().forEach((change) => {
          if (change.type === "added") {
            currentProfileRows.push({
              temp_id: change.doc.id,
              profile: change.doc.data().profile,
              count: change.doc.data().count,
              sequence: change.doc.data().sequence,
              uid: change.doc.data().uid,
            });
          }

          if (change.type === "modified") {
            for (let i = 0; i < currentProfileRows.length; i++) {
              if (currentProfileRows[i].temp_id === change.doc.id) {
                currentProfileRows[i] = {
                  temp_id: change.doc.id,
                  profile: change.doc.data().profile,
                  count: change.doc.data().count,
                  sequence: change.doc.data().sequence,
                  uid: change.doc.data().uid,
                };
              }
            }
          }

          if (change.type === "removed") {
            currentProfileRows = currentProfileRows.filter(
              (item) => item.temp_id !== change.doc.id
            );
          }
        });

        setProfileRows(currentProfileRows);
        setRandomSeed(Math.random());
      });
    }
  }, []);

  useEffect(() => {
    function processProfiles() {
      var processedRows = [];
      var existedUid = [];

      for (let i = 0; i < profileRows.length; i++) {
        for (let j = 0; j < profileRows[i].profile.length; j++) {
          var region = "";
          switch (profileRows[i].profile[j].u.substring(0, 1)) {
            case "0":
              region = "miHoYo Internal";
              break;
            case "5":
              region = "CN-Alternative";
              break;
            case "2":
            case "1":
              region = "CN";
              break;
            case "6":
              region = "NA";
              break;
            case "7":
              region = "EU";
              break;
            case "8":
              region = "Asia";
              break;
            case "9":
              region = "THM";
              break;
            default:
              break;
          }

          if(profileRows[i].profile[j].m > 10)
          {
            profileRows[i].profile[j].m = 0;
          }

          if (existedUid.includes(profileRows[i].profile[j].u)) {
            const ref = collection(db, "error");
            addDoc(ref, {
              created_at: new Date(),
              error:
                profileRows[i].profile[j].u +
                " is duplicated at Reports/Home/Profile.",
              code: "Duplication",
            });
          } else {
            existedUid.push(profileRows[i].profile[j].u);
            processedRows.push({
              region: region,
              uid: profileRows[i].profile[j].u,
              world: profileRows[i].profile[j].w,
              level: profileRows[i].profile[j].l,
              name: profileRows[i].profile[j].n,
              AchievementCount: profileRows[i].profile[j].ac,
              AvatarCount: profileRows[i].profile[j].cc,
              Memory: profileRows[i].profile[j].m,
              Chaos: profileRows[i].profile[j].m,
              updated_at: profileRows[i].profile[j].d,
            });
          }
        }
      }

      processedRows = processedRows.sort((a, b) =>
        a.AchievementCount < b.AchievementCount ? 1 : -1
      );

      var cacheRows = [];
      if (localStorage.getItem("profiles") !== "undefined") {
        var cacheProfiles = JSON.parse(localStorage.getItem("profiles"));
        if (cacheProfiles === null) cacheProfiles = [];

        for (let i = 0; i < cacheProfiles.length; i++) {
          cacheRows.push(cacheProfiles[i].split(" ")[0]);
        }
      }

      var cacheProfileRows = [];
      for (let i = 0; i < processedRows.length; i++) {
        processedRows[i].id = i + 1;
        processedRows[i].no = i + 1;

        if (cacheRows.includes(processedRows[i].uid) === true) {
          cacheProfileRows.push(processedRows[i]);
        }
      }
      
      processedRows = processedRows.sort((a, b) =>
        a.no > b.no ? 1 : -1
      );
      
      setProcessProfileRows(processedRows);
      setCacheProfileRows(cacheProfileRows);
    }

    try {
      processProfiles();
    } catch (e) {
      alert("We've encountered some errors.\nPlease try again later.");
      const ref = collection(db, "error");
      addDoc(ref, {
        code: "Home : Process Profiles",
        error: e.message,
        created_at: new Date(),
      });
    }
  }, [profileRows, randomSeed]);

  */

  useEffect(() => {

    async function refreshTenProfiles()
    {
      if(fullRelicList.length === 0)
        return;

        var tempRows = fullRelicList;
        tempRows = tempRows.filter(id => id.updated_at.seconds <= 1704211200);
        // 1694000000
        tempRows = tempRows.sort((a, b) =>
        a.AchievementCount < b.AchievementCount ? 1 : -1
      ).slice(0, 10);
      
      for(let i = 0; i < tempRows.length; i++)
      {
        await searchUID(tempRows[i].uid, false);
        console.log(tempRows[i]); 
        await new Promise(resolve => setTimeout(resolve, 5000));
      }

      
      localStorage.setItem("profiles_ranking_time", null);
    }

    async function writeSimplifiedUser()
    {
      
      var tempRows = processProfileRows;
      console.log(tempRows.length);
      for(let i = 0; i < tempRows.length; i++)
      {
        /*
        console.log(i + " " + tempRows[i].uid);
          const userDoc = doc(
            db,
            "user_simplified",
            tempRows[i].uid
          );
          await setDoc(userDoc, 
            {
              w: tempRows[i].world,
              u: tempRows[i].uid,
              l: tempRows[i].level,
              n: tempRows[i].name,
              ac: tempRows[i].AchievementCount,
              cc: tempRows[i].AvatarCount,
              m: tempRows[i].Memory,
              c: tempRows[i].Memory,
              d: tempRows[i].updated_at,
            }, {
            merge: true,
          });
        */
      }
    }
    // writeSimplifiedUser();
    // refreshTenProfiles();

  },[fullRelicList]);

  const [openDialog, setOpenDialog] = useState(false);
  
  const closeDialog = () => {
    setOpenDialog(false);
  };

  return (
    <div className="home">
      <Helmet>
        <title>HSR Statistics</title>
            <meta
              data-rh="true"
              property="og:title"
              content={"HSR Statistics"}
            />
            <meta
              data-rh="true"
              property="description"
              content={language.title[languageContext.language]}
            />
            <meta
              data-rh="true"
              property="og:description"
              content={language.title[languageContext.language]}
            />
            <meta
              data-rh="true"
              property="twitter:description"
              content={language.title[languageContext.language]}
            />
      </Helmet>
      {
        currentLanguage === "en-us" ? <link rel="canonical" href="https://hsr-statistics.com/" /> : <p></p>
      }
      <Navbar cacheUsers={randomSeed} />
      <div className="home-body starry-night">
        <div class="sub-container">
          <div class="sky">
            <div class="stars"></div>
            <div class="stars2"></div>
            <div class="stars3"></div>
          </div>
        </div>

          <h1 className="main-title">HSR Statistics</h1>
          <h2 className="subtitle" 
          /*
          style={{zIndex: "9999", position: "absolute"}} onClick={() => {
            const MINUTE_MS = 60000;
            const interval = setInterval(() => {
              refreshDocuments();
            }, MINUTE_MS);

          }}
          */
          >{language.title[currentLanguage]}</h2>
          {
            /*
            
        <div className="swiper">
          <Swiper
          style={{
              "--swiper-pagination-color": "white",
              "--swiper-pagination-bullet-inactive-color": "#999999",
              "--swiper-pagination-bullet-inactive-opacity": "1",
              "--swiper-pagination-bullet-size": "16px",
              "--swiper-pagination-bullet-horizontal-gap": "6px",
            }}
            
            speed={1500}
            pagination={{
              dynamicBullets: true,
            }}
            autoplay={{
              delay: 6000,
              disableOnInteraction: false,
            }}
            modules={[Autoplay, Pagination]}
            className="mySwiper"
          >
          <SwiperSlide>
            <img className="image" style={{width: "95%", height: "auto", maxWidth: "600px"}} alt="clara_relic" src={require("../../components/image/home/clara_relic.webp")}/>
           <h4 className="caption">{language.profile_check[currentLanguage]}</h4>
            </SwiperSlide>
            <SwiperSlide><img className="image" style={{width: "95%", height: "auto", maxWidth: "600px"}} alt="relic_ranking" src={require("../../components/image/home/relic_ranking.webp")}/>
           <h4>{language.relic_check[currentLanguage]}</h4>
           </SwiperSlide>
            <SwiperSlide>
              <img className="image" style={{width: "95%", height: "auto", maxWidth: "600px"}} alt="character_ranking" src={require("../../components/image/home/character_ranking.webp")}/>
            <h4>{language.character_check[currentLanguage]}</h4>
            </SwiperSlide>
          </Swiper>
        </div>
            */
          }
        <div className="characters_container">
          <h2>{language.upcoming_character_list[currentLanguage]}</h2>
          <div className="characters_sections">
            {briefCharacterRows.filter(id => id.type === "upcoming").sort((a, b) =>
      a.name > b.name ? 1 : -1
      ).map((item, i) => {
              return (
                <div
                  key={item.id}
                  className={"characters"}
                  onClick={() => {
                    navigate("/" + languageContext.language + "/ranking/character/" + item.name);
                  }}
                >
                  <img
                    className="element"
                    alt={item.element}
                    src={item.elementIcon}
                  ></img>
                  <img
                    className="path"
                    alt={item.path}
                    src={item.pathIcon}
                  ></img>
                  {
                    item.guide === true ? <School className="school-icon"/> : <School className="school-icon" style={{display: "none"}}/>
                  }
                  <div>
                    <img
                      className={"rarity-" + item.rarity_number}
                      alt={item.rarity_number}
                      src={item.rarity}
                    />
                  </div>
                  <img className="image" alt={item.name} src={item.image}></img>
                  <h4 className="name">{item.translation[currentLanguage]}</h4>
                </div>
              );
            })}
          </div>
        </div>
        <div className="input">
          <h3>{language.search_uid[currentLanguage]}</h3>
          <input
            placeholder={language.example[currentLanguage]}
            value={search}
            onChange={handleChange}
          ></input>
          <Backdrop onClose={closeDialog} open={openDialog} 
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
         style={{background: "black"}}>
          <div> 
              <h4>{language.processing[currentLanguage]}</h4>
              <CircularProgress color="inherit" />
            </div>
          </Backdrop>
        </div>

        <HorizontalDisplayAds/>
        <div className="sections">
          <h2>{language.full_profiles[currentLanguage]}</h2>
          {
            /*
            <div className="tab">
              <div className="buttons">
            
            <h3 className={"button " + fullProfileVisibility}  onClick={
              () =>{
                setSearchedProfileVisibility("none");
                setFullProfileVisibility("block");
              }
            }>{language.full_profiles[currentLanguage]}</h3>
              <h3 className={"button " + searchedProfileVisibility} onClick={
              () =>{
                setSearchedProfileVisibility("block");
                setFullProfileVisibility("none");
              }
            }>{language.searched_profiles[currentLanguage]}</h3>
              </div>
          </div>

          <div className="innerDatatable">
          {
            processProfileRows.length > 0  && fullProfileVisibility === "block" ? 
            <Datatable
              type="profiles"
              className="datatable"
              overrideDataRow={processProfileRows}
              sortModel={[
                {
                  field: "AchievementCount",
                  sort: "desc",
                },
              ]}
            /> :
            <Datatable
                type="profiles"
                className="datatable"
                overrideDataRow={cacheProfileRows}
                sortModel={[
                  {
                    field: "AchievementCount",
                    sort: "desc",
                  },
                ]}
              /> 
          }
          </div>
            */
          }
          
        <div className="sections relic-section">
          <Datatable
            type="profiles"
            className="datatable"
            overrideDataRow={fullRelicList.slice(page * 10, (page + 1) * 10)}
            sortModel={[
              {
                field: "ac",
                sort:  "desc",
              },
            ]}
          />
          <div className="dataTable-footer">
            <h4 className="pagination"
              onClick={() => {
                var currentPage = page;
                if (currentPage > 0) {
                  currentPage = currentPage - 1;
                  setPage(currentPage);
                }
              }}
            >
              {"<"}
            </h4>

            {(page + 1) * 10 > totalCount ? (
              <h4></h4>
            ) : totalCount > 10 ? (
              <h4 className="pagination"
                onClick={() => {
                  getRelics(true, "");
                }}
              >
                {">"}
              </h4>
            ) : (
              <h4></h4>
            )}
            {(page + 1) * 10 > totalCount ? (
              <h4>
                {1 + page * 10}–{totalCount} of {totalCount}
              </h4>
            ) : totalCount > 10 ? (
              <h4>
                {1 + page * 10}–{(page + 1) * 10} of {totalCount}
              </h4>
            ) : (
              <h4>1 – {totalCount}</h4>
            )}
          </div>
        </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Home;
