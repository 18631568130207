import { useEffect, useRef, useState } from "react";
import Footer from "../../components/footer/footer";
import Navbar from "../../components/navbar/navbar";
import { addDoc, doc, getDoc,  getDocs, deleteDoc, updateDoc, increment, collection, query, where, limit } from "@firebase/firestore";
import { db } from "../../firebase";
import "./Profile.scss";
import {
  characterRows,
  rarity,
  lightConeRows,
  property,
  revertProperty,
  relic,
  isPropertyPercentage,
  searchUID,
  calculateRelic,
} from "../../components/utils";
import { useParams } from "react-router-dom";
import * as htmlToImage from "html-to-image";
import { Backdrop, Checkbox, CircularProgress, Slider } from "@mui/material";
import { SketchPicker } from "react-color";
import { LanguageContext } from "../../App";
import { useContext } from "react";
import { Helmet } from "react-helmet-async";
import { Image, DeleteOutline } from "@mui/icons-material";
import HorizontalDisplayAds from "../../components/ads/horizontalDisplayAds";

const Profile = () => {
  const [uid, setUid] = useState(0);
  const [hideUid, setHideUid] = useState(false);
  const [hideSubvalue, setHideSubvalue] = useState(false);
  const [hideName, setHideName] = useState(false);

  const [landscapeImage, setLandscapeImage] = useState("");
  const [landscapeBackground, setLandscapeBackground] = useState("");
  const landscapeImageFile = useRef(null);
  const landscapeBackgroundFile = useRef(null);
  const [imageLandscapeOffsetX, setLandscapeImageOffsetX] = useState(40);
  const [imageLandscapeOffsetY, setLandscapeImageOffsetY] = useState(80);
  const [imageLandscapeScale, setLandscapeImageScale] = useState(100);
  const [imageLandscapeBorderRadius, setLandscapeImageBorderRadius] =
    useState(30);
  const [imageLandscapeBorderColour, setLandscapeImageBorderColour] =
    useState("rgb(128, 128, 128)");

  const [portraitImage, setPortraitImage] = useState("");
  const [portraitBackground, setPortraitBackground] = useState("");
  const portraitImageFile = useRef(null);
  const portraitBackgroundFile = useRef(null);
  const [imagePortraitOffsetX, setPortraitImageOffsetX] = useState(0);
  const [imagePortraitOffsetY, setPortraitImageOffsetY] = useState(100);
  const [imagePortraitScale, setPortraitImageScale] = useState(100);
  const [imagePortraitBorderRadius, setPortraitImageBorderRadius] =
    useState(10);
  const [imagePortraitBorderColour, setPortraitImageBorderColour] =
    useState("rgb(128, 128, 128)");

  const [portraitMobileImage, setPortraitMobileImage] = useState("");
  const [portraitMobileBackground, setPortraitMobileBackground] = useState("");
  const portraitMobileImageFile = useRef(null);
  const portraitMobileBackgroundFile = useRef(null);
  const [imagePortraitMobileOffsetX, setPortraitMobileImageOffsetX] =
    useState(0);
  const [imagePortraitMobileOffsetY, setPortraitMobileImageOffsetY] =
    useState(30);
  const [imagePortraitMobileScale, setPortraitMobileImageScale] = useState(100);
  const [imagePortraitMobileBorderRadius, setPortraitMobileImageBorderRadius] =
    useState(20);
  const [imagePortraitMobileBorderColour, setPortraitMobileImageBorderColour] =
    useState("rgb(128, 128, 128)");

  const [highlightedClass, setHighlightedClass] = useState("");

  const landscapeDownloadReference = useRef(null);
  const portraitDownloadReference = useRef(null);
  const portraitDownloadMobileReference = useRef(null);

  const languageContext = useContext(LanguageContext);
  const [popupVisiblity, setPopupVisibility] = useState("collapse");
  const [portraitSectionDisplay, setPortraitSectionDisplay] = useState("block");
  const [mobileSectionDisplay, setMobileSectionDisplay] = useState("block");
  const [nameFontSize, setNameFontSize] = useState("30px");

  // Initialize Mobile or Portrait View
  useEffect(() => {
    function initializeView() {
      if (window.innerWidth >= 900) {
        setPortraitSectionDisplay("block");
        setMobileSectionDisplay("none");
      } else {
        setPortraitSectionDisplay("none");
        setMobileSectionDisplay("block");
      }

      var hideUidCache = JSON.parse(localStorage.getItem("profiles-hide-uid"));
      if(hideUidCache === null)
      {
        localStorage.setItem("profiles-hide-uid", false);
      }
      else{
        setHideUid(hideUidCache);
      }

      var hideNameCache = JSON.parse(localStorage.getItem("profiles-hide-name"));
      if(hideNameCache === null)
      {
        localStorage.setItem("profiles-hide-name", false);
      }
      else{
        setHideName(hideNameCache);
      }

      var hideSubvalueCache = JSON.parse(localStorage.getItem("profiles-hide-subvalue"));
      if(hideSubvalueCache === null)
      {
        localStorage.setItem("profiles-hide-subvalue", false);
      }
      else{
        setHideSubvalue(hideSubvalueCache);
      }
    }

    initializeView();
  }, []);

  window.addEventListener(
    "scroll",
    function () {
      if (highlightedClass !== "") {
        dehighlightClass(highlightedClass);
        setHighlightedClass("");
      }
    },
    false
  );
  const language = {
    delete_confirmation: {
      "en-us": "Press 'OK' to continue with the deletion.",
      "zh-cn": "按“OK”便可确认删除。",
      "zh-tw": "按“OK”便可確認刪除。",
    },
    processing: {
      "en-us": "Processing ...",
      "zh-cn": "处理中 。。",
      "zh-tw": "處理中 。。",
    },
    updated_at: {
      "zh-cn": "最后更新于 ：",
      "zh-tw": "最後更新於 ：",
      "en-us": "Last Updated At : ",
    },
    world: {
      "zh-cn": "世界",
      "zh-tw": "世界",
      "en-us": "World",
    },
    achievement: {
      "zh-cn": "成就",
      "zh-tw": "成就",
      "en-us": "Achievements",
    },
    chaos: {
      "zh-cn": "混沌回忆",
      "zh-tw": "混沌回憶",
      "en-us": "Memory of Chaos",
    },
    refresh: {
      "zh-cn": "刷新",
      "zh-tw": "刷新",
      "en-us": "Refresh",
    },
    saved_characters: {
      "zh-cn": "已存角色",
      "zh-tw": "已存角色",
      "en-us": "Saved Characters",
    },
    relic_scorer: {
      "zh-cn": "遗器计分器",
      "zh-tw": "遺器計分器",
      "en-us": "Relic Scorer",
    },
    portrait_view: {
      "zh-cn": "纵向视图",
      "zh-tw": "縱向視圖",
      "en-us": "Portrait View",
    },
    mobile_view: {
      "zh-cn": "手机视图",
      "zh-tw": "手機視圖",
      "en-us": "Mobile View",
    },
    algorithm_update: {
      "zh-cn": "遗器分数算法更新于2023-09-20 3:07pm",
      "zh-tw": "遺器分數算法更新於2023-09-20 3:07pm",
      "en-us": "Relic score algorithm was updated on 2023-09-20 3:07pm",
    },
    character_card: {
      "zh-cn": "角色卡",
      "zh-tw": "角色卡",
      "en-us": "Character Card",
    },
    relic_summary: {
      "zh-cn": "遗器分数概要",
      "zh-tw": "遺器分數概要",
      "en-us": "Relic Score Summary",
    },
    relic_scorer: {
      "zh-cn": "遗器计分器",
      "zh-tw": "遺器計分器",
      "en-us": "Relic Scorer",
    },
    image_border_colour: {
      "zh-cn": "图像边框颜色",
      "zh-tw": "圖像邊框顏色",
      "en-us": "Image Border Colour",
    },
    image_border_radius: {
      "zh-cn": "图像边框半径",
      "zh-tw": "圖像邊框半徑",
      "en-us": "Image Border Radius",
    },
    custom_image: {
      "zh-cn": "自定义图像",
      "zh-tw": "自定義圖像",
      "en-us": "Custom Image",
    },
    custom_background: {
      "zh-cn": "自定义背景",
      "zh-tw": "自定義背景",
      "en-us": "Custom Background",
    },
    hide_name: {
      "zh-cn": "隐藏名字",
      "zh-tw": "隱藏名字",
      "en-us": "Hide Name",
    },
    hide_uid: {
      "zh-cn": "隐藏 UID",
      "zh-tw": "隱藏 UID",
      "en-us": "Hide UID",
    },
    hide_subvalue: {
      "zh-cn": "隐藏数据细目",
      "zh-tw": "隱藏數據細目",
      "en-us": "Hide Stats Details",
    },
    download: {
      "zh-cn": "下载",
      "zh-tw": "下載",
      "en-us": "Download",
    },
    score: {
      "zh-cn": "分数",
      "zh-tw": "分數",
      "en-us": "Score",
    },
    grade: {
      "zh-cn": "级别",
      "zh-tw": "級別",
      "en-us": "Grade",
    },
    formula_title: {
      "zh-cn": "公式算法",
      "zh-tw": "公式算法",
      "en-us": "Formula Algorithm",
    },
    formula_substat: {
      "zh-cn": "副词条：属性值 x 倍率 - 比如：50 攻击力 x 0.04 = 2分",
      "zh-tw": "副詞條：屬性值 x 倍率 - 比如：50 攻擊力 x 0.04 = 2分",
      "en-us":
        "Substat: Property Value x Rate - Example: 50 ATK x 0.04 = 2 Score",
    },
    formula_mainstat: {
      "zh-cn":
        "主词条：（帽子，手套，元素伤害，充能，治疗 1 / 其他部位有效词条倍率 1.6 / 其他部位无效词条倍率 0.2 ）x 遗器稀有度 x 遗器等级",
      "zh-tw":
        "主詞條：（帽子，手套，元素傷害，充能，治療 1 / 其他部位有效詞條倍率 1.6 / 其他部位無效詞條倍率 0.2 ）x 遺器稀有度 x 遺器等級",
      "en-us":
        "Main Stat: (Helmet, Glove, Elemental DMG, Energy Regeneration, Outgoing Heal 1 / Other Parts with Effective Stat 1.6 / Other Parts with Ineffective Stat 0.2 ) x Relic Rarity x Relic Level",
    },
    close: {
      "en-us": "Close",
      "zh-cn": "关闭",
      "zh-tw": "關閉",
    },
    eidolon: {
      "en-us": "E",
      "zh-cn": "魂",
      "zh-tw": "魂",
    },
    choose_image: {
      "en-us": "Choose Image",
      "zh-cn": "选择图像",
      "zh-tw": "選擇圖像",
    },
    choose_background: {
      "en-us": "Choose Background",
      "zh-cn": "选择背景",
      "zh-tw": "選擇背景",
    },
  };

  const params = useParams();
  useEffect(() => {
    setUid(params.id);
  }, [params]);

  const [profile, setProfile] = useState({});
  const [profileIcon, setProfileIcon] = useState(characterRows[0].image);

  const [characterList, setCharacterList] = useState([]);
  const [displayCharacterId, setDisplayCharacterId] = useState("");
  const [displayCharacter, setDisplayCharacter] = useState({});
  // Retrieve User
  useEffect(() => {

    async function retrieveUser() {
      try {
        if (uid === 0) return;

        const userRef = doc(db, "user", uid);
        const userDocSnap = await getDoc(userRef);

        if (userDocSnap.exists()) {
          var data = userDocSnap.data();

          setLandscapeBackground(
            "url(" + require("../../components/image/starrail_bg.webp") + ")"
          );
          setPortraitBackground(
            "url(" + require("../../components/image/home_bg2.webp") + ")"
          );
          setPortraitMobileBackground(
            "url(" + require("../../components/image/home_bg2.webp") + ")"
          );

          if (data.cl.length > 0) {
            for (let i = 0; i < characterRows.length; i++) {
              if (characterRows[i].id.includes(data.cl[0].id)) {
                var characterDetails = characterRows[i];
                characterDetails.id = data.cl[0].id;

                setLandscapeImage(characterDetails.background);
                setPortraitImage(characterDetails.background);
                setPortraitMobileImage(characterDetails.background);
                setDisplayCharacterId(characterDetails);
                break;
              }
            }
          } else {
            setDisplayCharacterId({});
          }

          switch (String(userDocSnap.data().i)) {
            case "8001":
            case "8002":
              data.i = "8001,8002";
              break;
            case "8003":
            case "8004":
              data.i = "8003,8004";
              break;
            default:
              break;
          }

          data.m = data.c;
          
          if(data.c > 10)
          {
            data.c = 0;
          }

          setProfile(data);
        } else {
          window.location.href = "/";
        }
      } catch (e) {

        
        if(e.message === "Failed to get document because the client is offline.")
        {
          alert("The internet is not stable.\nPlease try again later.");
        }
        else
        {
          alert("We've encountered some errors.\nPlease try again later.");
          const ref = collection(db, "error");
          addDoc(ref, {
            code: "Profile : Retrieve Character",
            uid: params.id,
            error: e.message,
            created_at: new Date(),
          });
        }
        
      }
    }
    retrieveUser();
  }, [uid]);

  // Retrieve Display Character
  useEffect(() => {
    async function getCharacterData() {
      try {
        if (typeof displayCharacterId.id === "undefined") {
          return;
        }

        const characterRef = doc(
          db,
          "character/id/" + displayCharacterId.id,
          uid
        );

        const characterDocSnap = await getDoc(characterRef);

        if (characterDocSnap.exists()) {
          var characterData = characterDocSnap.data();
          characterData = calculateRelic(
            characterData,
            languageContext.language,
            displayCharacterId
          );

          setLandscapeImageOffsetX(40);
          setLandscapeImageOffsetY(80);
          setLandscapeImageScale(100);

          setPortraitImageOffsetX(0);
          setPortraitImageOffsetY(100);
          setPortraitImageScale(100);

          setPortraitMobileImageOffsetX(0);
          setPortraitMobileImageOffsetY(30);
          setPortraitMobileImageScale(100);
          setDisplayCharacter(characterData);
        }
      } catch (e) {

        if(e.message === "Failed to get document because the client is offline.")
        {
          alert("The internet is not stable.\nPlease try again later.");
        }
        else
        {
          alert("We've encountered some errors.\nPlease try again later.");
          const ref = collection(db, "error");
          addDoc(ref, {
            code: "Profile : Get Character Data",
            uid: params.id,
            error: e.message,
            created_at: new Date(),
          });
        }

      }
    }
    getCharacterData();
  }, [displayCharacterId, languageContext]);
  useEffect(() => {
    function getProfileIcon() {
      try {
        if (typeof profile.i === "undefined") return;

        var characterList = [];
        for (let i = 0; i < characterRows.length; i++) {
          if (characterRows[i].id === profile.i) {
            setProfileIcon(characterRows[i].image);
          }
          for (let j = 0; j < profile.ci.length; j++) {
            if (characterRows[i].id === profile.ci[j]) {
              characterList.push(characterRows[i]);
              break;
            }
          }
        }
      } catch (e) {
        
        if(e.message === "Failed to get document because the client is offline.")
        {
          alert("The internet is not stable.\nPlease try again later.");
        }
        else
        {
          alert("We've encountered some errors.\nPlease try again later.");
          const ref = collection(db, "error");
          addDoc(ref, {
            code: "Profile : Get Profile Icon",
            uid: params.id,
            error: e.message,
            created_at: new Date(),
          });
        }
        
      }
      setCharacterList(characterList);
    }

    getProfileIcon();
  }, [profile]);

  const portraitMobileImageContainer = document.getElementById(
    "portrait-mobile-image-container"
  );
  const portraitImageContainer = document.getElementById(
    "portrait-image-container"
  );
  const landscapeImageContainer = document.getElementById(
    "landscape-image-container"
  );
  useEffect(() => {
    function adjustLandscapeImage() {
      if (landscapeImageContainer === null) return;

      landscapeImageContainer.style.filter =
        "drop-shadow(0px 0px " +
        imageLandscapeBorderRadius +
        "px " +
        imageLandscapeBorderColour +
        ")";
      landscapeImageContainer.style.marginLeft = imageLandscapeOffsetX + "px";
      landscapeImageContainer.style.marginTop = imageLandscapeOffsetY + "px";
      landscapeImageContainer.style.scale = imageLandscapeScale / 100;
    }
    adjustLandscapeImage();
  }, [
    imageLandscapeOffsetX,
    imageLandscapeOffsetY,
    imageLandscapeScale,
    imageLandscapeBorderColour,
    imageLandscapeBorderRadius,
  ]);

  useEffect(() => {
    function adjustPortraitImage() {
      if (portraitImageContainer === null) return;

      portraitImageContainer.style.filter =
        "drop-shadow(0px 0px " +
        imagePortraitBorderRadius +
        "px " +
        imagePortraitBorderColour +
        ")";

      portraitImageContainer.style.marginLeft = imagePortraitOffsetX + "px";
      portraitImageContainer.style.marginTop = imagePortraitOffsetY + "px";
      portraitImageContainer.style.scale = imagePortraitScale / 100;
    }
    adjustPortraitImage();
  }, [
    imagePortraitOffsetX,
    imagePortraitOffsetY,
    imagePortraitScale,
    imagePortraitBorderColour,
    imagePortraitBorderRadius,
  ]);

  useEffect(() => {
    function adjustPortraitMobileImage() {
      if (portraitMobileImageContainer === null) return;

      portraitMobileImageContainer.style.filter =
        "drop-shadow(0px 0px " +
        imagePortraitMobileBorderRadius +
        "px " +
        imagePortraitMobileBorderColour +
        ")";
      portraitMobileImageContainer.style.marginLeft =
        imagePortraitMobileOffsetX + "px";
      portraitMobileImageContainer.style.marginTop =
        imagePortraitMobileOffsetY + "px";
      portraitMobileImageContainer.style.scale = imagePortraitMobileScale / 100;
    }
    adjustPortraitMobileImage();
  }, [
    imagePortraitMobileOffsetX,
    imagePortraitMobileOffsetY,
    imagePortraitMobileScale,
    imagePortraitMobileBorderColour,
    imagePortraitMobileBorderRadius,
  ]);

  function highlightClass(className) {
    setHighlightedClass(className);
    var elms = document.getElementsByClassName("property-" + className);
    var n = elms.length;

    for (var i = 0; i < n; i++) {
      elms[i].style.cursor = "pointer";
      elms[i].style.transition = "all 0.2s ease-in-out";
      elms[i].style["-webkit-filter"] =
        "drop-shadow(0px 0px 5px red) drop-shadow(0px 0px 10px red) drop-shadow(0px 0px 15px red)";
    }
  }

  function dehighlightClass(className) {
    var elms = document.getElementsByClassName("property-" + className);
    var n = elms.length;

    for (var i = 0; i < n; i++) {
      elms[i].style["-webkit-filter"] = "";
    }
  }

  const handleLandscapeChangeComplete = (color) => {
    setLandscapeImageBorderColour(color.hex);
  };

  const handlePortraitChangeComplete = (color) => {
    setPortraitImageBorderColour(color.hex);
  };

  const handlePortraitMobileChangeComplete = (color) => {
    setPortraitMobileImageBorderColour(color.hex);
  };

  const [openDialog, setOpenDialog] = useState(false);
  
  const closeDialog = () => {
    setOpenDialog(false);
  };

  return (
    <div className="profile starry-night">
    <div class="sub-container">
      <div class="sky">
        <div class="stars"></div>
        <div class="stars2"></div>
        <div class="stars3"></div>
      </div>
    </div>
      <div
        className="popup-formula"
        id="popup-formula"
        style={{ visibility: popupVisiblity }}
      >
        {typeof displayCharacterId.formula === "object" ? (
          <div className="formula">
            <h2
              className="closeButton"
              onClick={() => {
                if (popupVisiblity === "collapse") {
                  setPopupVisibility("visible");
                } else {
                  setPopupVisibility("collapse");
                }
              }}
            >
              x
            </h2>
            <h2 className="formula-title">
              {displayCharacterId.translation[languageContext.language] + " - "}
              {language.formula_title[languageContext.language]}
            </h2>
            <h4>{language.formula_mainstat[languageContext.language]}</h4>
            <h4>{language.formula_substat[languageContext.language]}</h4>
            {property
              .filter((id) => id.type === "relic")
              .map((item, i) => {
                return (
                  <div className="formulas" key={i}>
                    <div className="name">
                      {item.translation[languageContext.language]}
                    </div>
                    <div className="rate">
                      {" - " + displayCharacterId.formula.stats[item.id]}
                    </div>
                  </div>
                );
              })}
            <h4 className="remark">
              {language.algorithm_update[languageContext.language]}
            </h4>
            <div className="ranks">
              <div className="rank">
                <div className="rank-S+">S+</div>
                <div>
                  {" : " + language.score[languageContext.language] + " >= 52"}
                </div>
              </div>
              <div className="rank">
                <div className="rank-S">S</div>
                <div>
                  {" : " + language.score[languageContext.language] + " >= 42"}
                </div>
              </div>
              <div className="rank">
                <div className="rank-A">A</div>
                <div>
                  {" : " + language.score[languageContext.language] + " >= 32"}
                </div>
              </div>
              <div className="rank">
                <div className="rank-B">B</div>
                <div>
                  {" : " + language.score[languageContext.language] + " >= 25"}
                </div>
              </div>
              <div className="rank">
                <div className="rank-C">C</div>
                <div>
                  {" : " + language.score[languageContext.language] + " >= 20"}
                </div>
              </div>
              <div className="rank">
                <div className="rank-D">D</div>
                <div>
                  {" : " + language.score[languageContext.language] + " >= 15"}
                </div>
              </div>
              <div className="rank">
                <div className="rank-E">E</div>
                <div>
                  {" : " + language.score[languageContext.language] + " < 15"}
                </div>
              </div>
            </div>
            <h2
              className="button"
              onClick={() => {
                if (popupVisiblity === "collapse") {
                  setPopupVisibility("visible");
                } else {
                  setPopupVisibility("collapse");
                }
              }}
            >
              {language.close[languageContext.language]}
            </h2>
            <br></br>
            <br></br>
          </div>
        ) : (
          <div></div>
        )}
      </div>
      <Navbar />
      {uid === 0 ? (
        <div></div>
      ) : (
        <div>
          {typeof profile.n !== "undefined" ? (
            <Helmet>
              <title>{profile.n + "'s Profile | HSR Statistics"}</title>
              <meta
                name="og:description"
                content="Honkai: Star Rail's Character Card and Relic Scorer"
              />
            </Helmet>
          ) : (
            <div></div>
          )}

          <div className="top_section">
            <div className="item left-top">
              <img alt="profile" className="icon" src={profileIcon}></img>
              <div className="name">
                <h1>{profile.n}</h1>
                <h4>LV {profile.l}</h4>
                <h4>
                  {language.world[languageContext.language]} {profile.w}
                </h4>
              </div>
            </div>
            <div className="item">
              <div className="details">
                <img
                  alt="achievement"
                  className="icon achievement"
                  src={require("../../components/image/icon/achivement.webp")}
                ></img>
                <h4>{language.achievement[languageContext.language]}</h4>
                <h2>{profile.ac}</h2>
              </div>
              {/*
              <div className="details">
                <img
                  alt="forgotten_hall"
                  className="icon forgottenHall"
                  src={require("../../components/image/icon/forgotten_hall.png")}
                ></img>
                <h4>Forgotten Hall</h4>
                <h2>{profile.m}</h2>
              </div>
                */}
              <div className="details">
                <img
                  alt="memory_of_chaos"
                  className="icon memoryOfChaos"
                  src={require("../../components/image/icon/forgotten_hall.png")}
                ></img>
                <h4>{language.chaos[languageContext.language]}</h4>
                <h2>{profile.m}</h2>
              </div>
            </div>
          </div>
            {
              typeof profile.d !== "undefined" ? 
              <div className="refresh">
              <h4 className="updated_at">{language.updated_at[languageContext.language]}{ new Date(profile.d.seconds* 1000).toLocaleString('en-US',
              {
                year: 'numeric', month: 'short', day: 'numeric',
                hour: '2-digit', minute: '2-digit', hourCycle: 'h23'
              })}</h4>
              

<Backdrop onClose={closeDialog} open={openDialog} 
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
         style={{background: "black"}}>
          <div> 
              <h4>{language.processing[languageContext.language]}</h4>
              <CircularProgress color="inherit" />
            </div>
          </Backdrop>
              <img
                onClick={() => {
                  
                    async function refreshProfile() {
                      setOpenDialog(true);
                      await searchUID(params.id, false, languageContext.language);
                      window.location.reload();
                    }
                    refreshProfile();
                }}      
                      alt="Refresh Icon"
                      className="icon refresh_icon"
                      src={require("../../components/image/icon/refresh_icon.png")}
                    ></img>
                    </div> : <h4></h4>
            }

          <div className="characters_container">
            <h2>{language.saved_characters[languageContext.language]}</h2>
            <div className="characters_sections" id="characters_sections">
              {characterList.map((item, i) => {
                return (
                  <div
                    key={item.id}
                    className={
                      item.id === displayCharacterId.id
                        ? "characters active"
                        : "characters"
                    }
                    onClick={() => {
                      setLandscapeImage(item.background);
                      setPortraitImage(item.background);
                      setPortraitMobileImage(item.background);
                      setDisplayCharacterId(item);
                      /*
                                    setSearch("");
                                    handleSelectedCharacter(item.id);
                                    const element = document.getElementById("character_card_section");
                                    element.scrollIntoView({ behavior: "smooth"});
                                    */
                    }}
                  >
                    <div>
                      <DeleteOutline className="delete-button" onClick={async () => {

                        if (window.confirm(language.delete_confirmation[languageContext.language])) {
                        } else {
                          return;
                        }

                        setOpenDialog(true);
                        // delete from report-two/character/id
                        const reportChacterIdQuery = query(collection(db, "report-two/character/" + item.id), 
                        where("uid", "array-contains-any", [params.id]), limit(1));

                        const snapshot = await getDocs(reportChacterIdQuery);
                        snapshot.forEach(async (document) => {

                          var currentData = document.data();
                          currentData.count = currentData.count - 1;

                          currentData.uid = currentData.uid.filter(uid => uid != String(params.id));
                          currentData.character = currentData.character.filter(character => character.u != String(params.id));

                          const characterReportDoc = doc(db, "report-two/character/" + item.id, document.id);
                          await updateDoc(characterReportDoc, currentData);
                        });

                        // delete from character/id/
                        const characterIdRef = doc(db, "character/id/" + item.id, uid);
                        await deleteDoc(characterIdRef);
                        
                        // minus from count-two/id/
                        const countDocRef = doc(db, "count-two", item.id);
                        await updateDoc(countDocRef, {
                            count: increment(-1)
                        });
                       
                        // delete from user/ci and user/cl
                        const userRef = doc(db, "user", uid);
                        const userDocSnap = await getDoc(userRef);

                        var userData = userDocSnap.data();
                        userData.ci = userData.ci.filter(id => id != item.id);
                        userData.cl = userData.cl.filter(cl => cl.id != item.id);

                        await updateDoc(userRef, userData);
                        window.location.reload();
                      }}/>
                    </div>
                    <img
                      className="element"
                      alt={item.element}
                      src={item.elementIcon}
                    ></img>
                    <img
                      className="path"
                      alt={item.path}
                      src={item.pathIcon}
                    ></img>
                    <div>
                      {item.rarity_number === "four-star" ? (
                        <img
                          className="rarity-four-star"
                          alt={item.rarity}
                          src={rarity[0].image}
                        />
                      ) : (
                        <img
                          className="rarity-five-star"
                          alt={item.rarity}
                          src={rarity[1].image}
                        ></img>
                      )}
                    </div>
                    
                    <img
                      className="image"
                      alt={item.name}
                      src={item.image}
                    ></img>
                    <p>{item.translation[languageContext.language]}</p>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="horizontal-ads">
            <HorizontalDisplayAds />
          </div>

<div className="top_option">
    <Checkbox
      style={{
        color: "white",
      }}
      checked={hideName}
      onClick={() => {
        localStorage.setItem("profiles-hide-name", !hideName);
        setHideName(!hideName);
      }}
    />
    <h4>{language.hide_name[languageContext.language]}</h4>
    <Checkbox
      style={{
        color: "white",
      }}
      checked={hideUid}
      onClick={() => {
        localStorage.setItem("profiles-hide-uid", !hideUid);
        setHideUid(!hideUid);
      }}
    />
    <h4>{language.hide_uid[languageContext.language]}</h4>
    <Checkbox
      style={{
        color: "white",
      }}
      checked={hideSubvalue}
      onClick={() => {
        localStorage.setItem("profiles-hide-subvalue", !hideSubvalue);
        setHideSubvalue(!hideSubvalue);
      }}
    />
    <h4>{language.hide_subvalue[languageContext.language]}</h4>
  </div>
          <div
            className="portrait_section"
            style={{ display: portraitSectionDisplay }}
          >
            <div className="tab">
              <h2
                className="button"
                onClick={() => {
                  setPortraitSectionDisplay("none");
                  setMobileSectionDisplay("block");
                }}
              >
                {language.mobile_view[languageContext.language]}
              </h2>
              <h2
                className="button formula"
                onClick={() => {
                  if (popupVisiblity === "collapse") {
                    setPopupVisibility("visible");
                  } else {
                    setPopupVisibility("collapse");
                  }
                }}
              >
                {language.formula_title[languageContext.language]}
              </h2>
            </div>
            <h2 className="title">
              {language.relic_scorer[languageContext.language]}
            </h2>

            <div className="scrollViewer" id="scroll-container">
              <div
                id="displayContainer"
                className="displayContainer"
                ref={portraitDownloadReference}
                style={{ backgroundImage: portraitBackground }}
              >
                <div className="firstRow">
                  <div className="firstColumn">
                    <div className="name">
                      {typeof displayCharacterId === "object" ? (
                        <h2 className={languageContext.language + "-" + displayCharacterId.id}>
                          {
                            displayCharacterId.translation[
                              languageContext.language
                            ]
                          }
                        </h2>
                      ) : (
                        <h2></h2>
                      )}
                      {hideName === false ? (
                        <h4 className={languageContext.language + "-" + displayCharacterId.id}>- {displayCharacter.n}</h4>
                      ) : (
                        <p></p>
                      )}
                    </div>
                    <div className="level">
                      <h4 className={languageContext.language + "-" + displayCharacterId.id}>
                        LV {displayCharacter.l} / {displayCharacter.maxLevel}
                      </h4>
                    </div>
                    <div className="uid">
                      {hideUid === false ? (
                        <h4 className={languageContext.language + "-" + displayCharacterId.id}>{displayCharacter.u}</h4>
                      ) : (
                        <p></p>
                      )}
                    </div>
                    <div className="eidolon">
                      {displayCharacter.r > 0 ? (
                        <img
                          className="eidolon-img active"
                          alt={"e" + displayCharacter.r}
                          src={displayCharacterId["e" + displayCharacter.r]}
                        />
                      ) : (
                        <img
                          className="eidolon-img locked"
                          alt={"e" + displayCharacter.r}
                          src={displayCharacterId.e1}
                        />
                      )}
                      <h4>
                        {language.eidolon[languageContext.language]}
                        {displayCharacter.r}
                      </h4>
                    </div>
                  </div>
                  <div className="secondColumn" onMouseEnter={()=> {
                    const portraitButtons = document.getElementById("portrait-view-buttons");
                    portraitButtons.style.visibility = "visible";
                  }}
                  onMouseLeave={()=> {
                    const portraitButtons = document.getElementById("portrait-view-buttons");
                    portraitButtons.style.visibility = "collapse";
                  }}>
                    <img
                      alt="profile"
                      id="portrait-image-container"
                      src={portraitImage}
                    ></img>
                  </div>
                    <div className="buttons" id="portrait-view-buttons"  onMouseEnter={()=> {
                    const portraitButtons = document.getElementById("portrait-view-buttons");
                    portraitButtons.style.visibility = "visible";
                  }}
                  onMouseLeave={()=> {
                    const portraitButtons = document.getElementById("portrait-view-buttons");
                    portraitButtons.style.visibility = "collapse";
                  }}>
                      <h2
                        className="button imageButton"
                        onClick={() => {
                          portraitImageFile.current.click();
                        }}
                      >
                        <Image style={{ marginTop: "-3px" }} />
                        {language.choose_image[languageContext.language]}
                      </h2>
                      <div className="navigation">
                        <h2
                          className="button imageButton"
                          onClick={() => {
                            setPortraitImageOffsetX(
                              imagePortraitOffsetX - 10
                            );
                          }}
                        >
                          ←
                        </h2>
                        <h2
                          className="button imageButton"
                          onClick={() => {
                            setPortraitImageOffsetX(
                              imagePortraitOffsetX + 10
                            );
                          }}
                        >
                          →
                        </h2>
                        <h2
                          className="button imageButton"
                          onClick={() => {
                            setPortraitImageOffsetY(
                              imagePortraitOffsetY - 10
                            );
                          }}
                        >
                          ↑
                        </h2>
                        <h2
                          className="button imageButton"
                          onClick={() => {
                            setPortraitImageOffsetY(
                              imagePortraitOffsetY + 10
                            );
                          }}
                        >
                          ↓
                        </h2>
                      </div>
                      <div className="scale">
                        <h2
                          className="button imageButton"
                          onClick={() => {
                            setPortraitImageScale(
                              imagePortraitScale + 10
                            );
                          }}
                        >
                          +
                        </h2>
                        <h2
                          className="button imageButton"
                          onClick={() => {
                            if (imagePortraitScale > 20)
                              setPortraitImageScale(
                                imagePortraitScale - 10
                              );
                          }}
                        >
                          -
                        </h2>
                      </div>
                      <h2
                        className="button imageButton"
                        onClick={() => {
                          portraitBackgroundFile.current.click();
                        }}
                      >
                        <Image style={{ marginTop: "-3px" }} />
                        {language.choose_background[languageContext.language]}
                      </h2>
                    </div>
                  <div className="thirdColumn">
                    <div className="skill">
                      {typeof displayCharacter.s !== "undefined" &&
                      displayCharacter.s.length >= 4 ? (
                        <div style={{ display: "flex" }}>
                          <div className="left">
                            {displayCharacter.s.slice(0, 4).map((item, i) => {
                              return (
                                <div key={i}>
                                  <div className="circle">{item.l}</div>
                                  <img
                                    alt={item.i}
                                    src={item.image}
                                    style={{
                                      WebkitFilter:
                                        "drop-shadow(0px 0px 5px white)",
                                    }}
                                  />
                                </div>
                              );
                            })}
                          </div>
                          <div className="right">
                            {displayCharacter.s.slice(4).map((item, i) => {
                              return (
                                <div
                                  className={"property-" + item.i}
                                  key={i}
                                  onMouseOver={() => {
                                    highlightClass(item.i);
                                  }}
                                  onMouseLeave={() => {
                                    dehighlightClass(item.i);
                                  }}
                                >
                                  <div className="circle">x{item.l}</div>
                                  <img
                                    alt={item.i}
                                    src={item.image}
                                    style={{
                                      WebkitFilter:
                                        "drop-shadow(0px 0px 5px white)",
                                    }}
                                  />
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      ) : (
                        <div></div>
                      )}
                    </div>
                    <div className="lightcone lightcone-stats">
                      {typeof displayCharacter.lightcone !== "undefined" ? (
                        <div
                          className={
                            "card " + displayCharacter.lightcone.rarity_number
                          }
                        >
                          <img
                            className="lightcone-image"
                            alt="lightcone"
                            src={displayCharacter.lightcone.image}
                            style={{
                              WebkitFilter: "drop-shadow(0px 0px 5px white) ",
                              paddingTop: "5px",
                              paddingBottom: "5px",
                              marginLeft: "28px"
                            }}
                          />
                          {
                            displayCharacter.lightcone.rarity_number === "three-star" ?
                            <img
                              className="rarity"
                              alt="rarity"
                              style={{
                                marginLeft: "28px"
                              }}
                              src={displayCharacter.lightcone.rarity}
                            ></img> :
                            displayCharacter.lightcone.rarity_number === "four-star" ?
                            <img
                              className="rarity"
                              alt="rarity"
                              style={{
                                marginLeft: "33px"
                              }}
                              src={displayCharacter.lightcone.rarity}
                            ></img> 
                            :
                            <img
                              className="rarity"
                              alt="rarity"
                              style={{
                                marginLeft: "26px"
                              }}
                              src={displayCharacter.lightcone.rarity}
                            ></img> 
                             
                          }
                          <div className="row">
                            <div
                              className="circle"
                              style={{
                                color: displayCharacter.lc.rank_font_color,
                                background: displayCharacter.lc.rank_bg_color,
                              }}
                            >
                              {displayCharacter.lc.rank}
                            </div>
                            <h4 className="level">
                              LV {displayCharacter.lc.l}
                            </h4>
                          </div>
                          {
                            // Even Number Chinese
                            (languageContext.language === "zh-cn" ||
                              languageContext.language === "zh-tw") &&
                            displayCharacter.lightcone.translation[
                              languageContext.language
                            ].length %
                              2 ===
                              0 ? (
                              <h4
                                className="name"
                                style={{
                                  fontSize: "16px",
                                  paddingLeft: "4px",
                                  paddingRight: "4px",
                                }}
                              >
                                {
                                  displayCharacter.lightcone.translation[
                                    languageContext.language
                                  ]
                                }
                              </h4>
                            ) : // Odd Number Chinese
                            languageContext.language === "zh-cn" ||
                              languageContext.language === "zh-tw" ? (
                              <h4
                                className="name"
                                style={{
                                  fontSize: "16px",
                                  paddingLeft: "8px",
                                  paddingRight: "8px",
                                }}
                              >
                                {
                                  displayCharacter.lightcone.translation[
                                    languageContext.language
                                  ]
                                }
                              </h4>
                            ) : (
                              <h4 className="name">
                                {
                                  displayCharacter.lightcone.translation[
                                    languageContext.language
                                  ]
                                }
                              </h4>
                            )
                          }
                        </div>
                      ) : (
                        <div></div>
                      )}
                    </div>
                  </div>
                  <div className="forthColumn">
                    <div className="stats">
                      {typeof displayCharacter.property !== "undefined" ? (
                        <div>
                          {displayCharacter.property.map((item, i) => {
                            return (
                              <div
                                key={item.id}
                                className={
                                  "stat stat-height-" +
                                  displayCharacter.property.length +
                                  " property-" +
                                  item.id
                                }
                                onMouseOver={() => {
                                  highlightClass(item.id);
                                }}
                                onMouseLeave={() => {
                                  dehighlightClass(item.id);
                                }}
                              >
                                <img
                                  alt={item.name}
                                  className="icon"
                                  src={item.image}
                                />
                                <h2>{item.name}</h2>
                                {String(item.base).includes("%") ? (
                                  <div className={"value " + hideSubvalue}>
                                    <h2>{item.base}</h2>
                                  </div>
                                ) : (
                                  <div className={"value " + hideSubvalue}>
                                    <h2>{Number(item.total)}</h2>
                                    {
                                      // Base + Addition? + Lightcone?
                                      item.arrangement === 1 ? (
                                        <div className={"subvalue " + hideSubvalue}>
                                          <h4>{item.base}</h4>
                                          {typeof item.lightcone !==
                                          "undefined" ? (
                                            <h4 className="addition lightcone-stats">
                                              + {item.lightcone}
                                            </h4>
                                          ) : (
                                            <p></p>
                                          )}
                                          {item.addition !== "0" ? (
                                            <h4 className="addition">
                                              + {item.addition}
                                            </h4>
                                          ) : (
                                            <p></p>
                                          )}
                                        </div>
                                      ) : item.arrangement === 2 ? (
                                        <div className={"subvalue " + hideSubvalue}>
                                          <h4 className="addition">
                                            {item.addition}
                                          </h4>
                                        </div>
                                      ) : item.arrangement === 3 ? (
                                        <div className={"subvalue " + hideSubvalue}>
                                          <h4 className="addition lightcone-stats">
                                            {item.lightcone}
                                          </h4>
                                          {item.addition !== "0" ? (
                                            <h4 className="addition">
                                              + {item.addition}
                                            </h4>
                                          ) : (
                                            <p></p>
                                          )}
                                        </div>
                                      ) : item.arrangement === 4 ? (
                                        <div className={"subvalue " + hideSubvalue}>
                                          <h4 className="addition lightcone-stats">
                                            {item.lightcone}
                                          </h4>
                                        </div>
                                      ) : (
                                        // No need display
                                        <div></div>
                                      )
                                    }
                                  </div>
                                )}
                              </div>
                            );
                          })}
                        </div>
                      ) : (
                        <div></div>
                      )}
                    </div>
                  </div>
                </div>

                <div className="secondRow">
                  <div className="top-summary">
                    <h2 className="relic-summary-title">
                      {language.relic_summary[languageContext.language]}
                      {" : "}
                    </h2>
                    <div className="relic-summary-score">
                      <h2>{displayCharacter.total_score}</h2>
                      <h2 className={"rank-" + displayCharacter.total_grade}>
                        {displayCharacter.total_grade}
                      </h2>
                    </div>
                  </div>
                  {typeof displayCharacter.rl !== "undefined" ? (
                    <div className="relics">
                      {displayCharacter.rl.map((item, i) => {
                        return (
                          <div
                            key={item.i}
                            className={
                              item.r === 3
                                ? "relic three-star"
                                : item.r === 4
                                ? "relic four-star"
                                : item.r === 5
                                ? "relic five-star"
                                : "relic two-star"
                            }
                          >
                            <div className="relicTop">
                              {typeof item.set === "number" ? (
                                <div className="relic-set">
                                  <div
                                    className="relic-set-desc"
                                    style={{ backgroundColor: item.set_color }}
                                  >
                                    <h4>{item.set}</h4>
                                  </div>
                                </div>
                              ) : (
                                <div></div>
                              )}
                              <img
                                className="relic-image"
                                style={{ cursor: "pointer" }}
                                title={item.name}
                                alt={item.i}
                                src={item.image}
                              />
                              <h4 className="level">+ {item.l}</h4>
                              <div
                                className={
                                  "main-stat-container property-" + item.m.n
                                }
                                title={item.score_text}
                                onMouseOver={() => {
                                  highlightClass(item.m.n);
                                }}
                                onMouseLeave={() => {
                                  dehighlightClass(item.m.n);
                                }}
                              >
                                <div className="inner">
                                  <img
                                    className="main-stat"
                                    alt={item.m.name}
                                    src={item.m.image}
                                  ></img>
                                  <h4 className="main-value">{item.m.v}</h4>
                                </div>
                                <h4 className="description">{item.m.name}</h4>
                              </div>
                              <div className="vertical_line"></div>
                            </div>

                            <div className="horizontal_line"></div>
                            <div className="relic-score">
                              <div className="score">
                                <h4 className="score-title">
                                  {language.score[languageContext.language]}
                                </h4>
                                <h4 className="value">{item.score}</h4>
                              </div>
                              <div className="rank">
                                <h4 className="score-title">
                                  {language.grade[languageContext.language]}
                                </h4>
                                <h4 className={"value rank-" + item.grade}>
                                  {item.grade}
                                </h4>
                              </div>
                            </div>
                            <div className="horizontal_line"></div>
                            <div
                              className="subproperties"
                              title={item.score_text}
                            >
                              {item.s.map((subp, i) => {
                                return (
                                  <div
                                    key={i}
                                    className={"subproperty property-" + subp.n}
                                    onMouseOver={() => {
                                      highlightClass(subp.n);
                                    }}
                                    onMouseLeave={() => {
                                      dehighlightClass(subp.n);
                                    }}
                                  >
                                    <div className="inner">
                                      <img
                                        className={"sub-stat " + subp.n}
                                        alt={subp.n}
                                        src={subp.image}
                                      ></img>
                                      <h4
                                        className={
                                          "description score-" + subp.score
                                        }
                                      >
                                        {subp.name}
                                      </h4>
                                    </div>
                                    <div className="sub-value-container">
                                      {subp.e !== "undefined" && subp.e > 1 ? (
                                        <h4 className="enhancement">
                                          +{subp.e - 1}
                                        </h4>
                                      ) : (
                                        <h4 className="empty-enhancement">
                                          +0
                                        </h4>
                                      )}
                                      <h4
                                        className={
                                          "sub-value score-" + subp.score
                                        }
                                      >
                                        {subp.v}
                                      </h4>
                                    </div>
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  ) : (
                    <div></div>
                  )}
                </div>
              </div>
            </div>
            <div
              className="button"
              onClick={async () => {
                if (
                  typeof displayCharacter.u === "undefined" ||
                  typeof displayCharacterId.name === "undefined"
                )
                  return;

                const dataUrl = await htmlToImage.toPng(
                  portraitDownloadReference.current
                );

                // download image
                const link = document.createElement("a");
                link.download =
                  displayCharacter.u + "_" + displayCharacterId.name + ".png";
                link.href = dataUrl;
                link.click();
              }}
            >
              <h2>{language.download[languageContext.language]}</h2>
            </div>
            <div className="options">
              <div className="option">
                <h4>
                  {language.image_border_colour[languageContext.language]}
                </h4>
                <SketchPicker
                  color={imagePortraitBorderColour}
                  onChangeComplete={handlePortraitChangeComplete}
                />
                <h4>
                  {language.image_border_radius[languageContext.language]}
                </h4>
                <Slider
                  min={0}
                  max={100}
                  value={imagePortraitBorderRadius}
                  style={{ width: "90%" }}
                  onChange={(e, val) => {
                    setPortraitImageBorderRadius(val);
                  }}
                ></Slider>
              </div>
              <input
                style={{ display: "none" }}
                type="file"
                ref={portraitImageFile}
                onChange={(event) => {
                  try {
                    setPortraitImage(
                      URL.createObjectURL(event.target.files[0])
                    );
                  } catch (e) {}
                }}
              />
              <input
                style={{ display: "none" }}
                type="file"
                ref={portraitBackgroundFile}
                onChange={(event) => {
                  try {
                    setPortraitBackground(
                      "url(" + URL.createObjectURL(event.target.files[0]) + ")"
                    );
                  } catch (e) {}
                }}
              />
            </div>
            <br></br>
          </div>
          <div
            className="mobile_section"
            style={{ display: mobileSectionDisplay }}
          >
            <div className="tab">
              <h2
                className="button"
                onClick={() => {
                  setPortraitSectionDisplay("block");
                  setMobileSectionDisplay("none");
                }}
              >
                {language.portrait_view[languageContext.language]}
              </h2>
              <h2
                className="button formula"
                onClick={() => {
                  if (popupVisiblity === "collapse") {
                    setPopupVisibility("visible");
                  } else {
                    setPopupVisibility("collapse");
                  }
                }}
              >
                {language.formula_title[languageContext.language]}
              </h2>
            </div>
            <h2 className="title">
              {language.relic_scorer[languageContext.language]}
            </h2>

            <div className="scrollViewer" id="scroll-container">
              <div
                id="displayContainer"
                className="displayContainer"
                ref={portraitDownloadMobileReference}
                style={{ backgroundImage: portraitMobileBackground }}
              >
                <div className="firstRow">
                  <div className="firstColumn">
                    <div className="name">
                      {typeof displayCharacterId === "object" ? (
                        <h2 className={languageContext.language + "-" + displayCharacterId.id}>
                          {
                            displayCharacterId.translation[
                              languageContext.language
                            ]
                          }
                        </h2>
                      ) : (
                        <h2></h2>
                      )}
                      {hideName === false ? (
                        <h4 className={languageContext.language + "-" + displayCharacterId.id}>- {displayCharacter.n}</h4>
                      ) : (
                        <p></p>
                      )}
                    </div>
                    <div className="level">
                      <h4 className={languageContext.language + "-" + displayCharacterId.id}>
                        LV {displayCharacter.l} / {displayCharacter.maxLevel}
                      </h4>
                    </div>

                    <div className="uid">
                      {hideUid === false ? (
                        <h4 className={languageContext.language + "-" + displayCharacterId.id}>{displayCharacter.u}</h4>
                      ) : (
                        <p></p>
                      )}
                    </div>
                    <div className="eidolon">
                      {displayCharacter.r > 0 ? (
                        <img
                          className="eidolon-img active"
                          alt={"e" + displayCharacter.r}
                          src={displayCharacterId["e" + displayCharacter.r]}
                        />
                      ) : (
                        <img
                          className="eidolon-img locked"
                          alt={"e" + displayCharacter.r}
                          src={displayCharacterId.e1}
                        />
                      )}
                      <h4>
                        {language.eidolon[languageContext.language]}
                        {displayCharacter.r}
                      </h4>
                    </div>
                  </div>
                  <div className="secondColumn" onMouseEnter={()=> {
                    const mobileButtons = document.getElementById("mobile-view-buttons");
                    mobileButtons.style.visibility = "visible";
                  }}
                  onMouseLeave={()=> {
                    const mobileButtons = document.getElementById("mobile-view-buttons");
                    mobileButtons.style.visibility = "collapse";
                  }}>
                    <img
                      alt="profile"
                      id="portrait-mobile-image-container"
                      src={portraitMobileImage}
                    ></img>
                  </div>
                    <div className="buttons" id="mobile-view-buttons"  onMouseEnter={()=> {
                    const mobileButtons = document.getElementById("mobile-view-buttons");
                    mobileButtons.style.visibility = "visible";
                  }}
                  onMouseLeave={()=> {
                    const mobileButtons = document.getElementById("mobile-view-buttons");
                    mobileButtons.style.visibility = "collapse";
                  }}>
                      <h2
                        className="button imageButton"
                        onClick={() => {
                          portraitMobileImageFile.current.click();
                        }}
                      >
                        <Image style={{ marginTop: "-3px" }} />
                        {language.choose_image[languageContext.language]}
                      </h2>
                      <div className="navigation">
                        <h2
                          className="button imageButton"
                          onClick={() => {
                            setPortraitMobileImageOffsetX(
                              imagePortraitMobileOffsetX - 10
                            );
                          }}
                        >
                          ←
                        </h2>
                        <h2
                          className="button imageButton"
                          onClick={() => {
                            setPortraitMobileImageOffsetX(
                              imagePortraitMobileOffsetX + 10
                            );
                          }}
                        >
                          →
                        </h2>
                        <h2
                          className="button imageButton"
                          onClick={() => {
                            setPortraitMobileImageOffsetY(
                              imagePortraitMobileOffsetY - 10
                            );
                          }}
                        >
                          ↑
                        </h2>
                        <h2
                          className="button imageButton"
                          onClick={() => {
                            setPortraitMobileImageOffsetY(
                              imagePortraitMobileOffsetY + 10
                            );
                          }}
                        >
                          ↓
                        </h2>
                      </div>
                      <div className="scale">
                        <h2
                          className="button imageButton"
                          onClick={() => {
                            setPortraitMobileImageScale(
                              imagePortraitMobileScale + 10
                            );
                          }}
                        >
                          +
                        </h2>
                        <h2
                          className="button imageButton"
                          onClick={() => {
                            if (imagePortraitMobileScale > 20)
                              setPortraitMobileImageScale(
                                imagePortraitMobileScale - 10
                              );
                          }}
                        >
                          -
                        </h2>
                      </div>
                      <h2
                        className="button imageButton"
                        onClick={() => {
                          portraitMobileBackgroundFile.current.click();
                        }}
                      >
                        <Image style={{ marginTop: "-3px" }} />
                        {language.choose_background[languageContext.language]}
                      </h2>
                    </div>
                  <div className="lightconeColumn">
                    <div className="lightcone lightcone-stats">
                      {typeof displayCharacter.lightcone !== "undefined" ? (
                        <div
                          className={
                            "card " + displayCharacter.lightcone.rarity_number
                          }
                        >
                          <img
                            className="lightcone-image"
                            alt="lightcone"
                            src={displayCharacter.lightcone.image}
                            style={{
                              WebkitFilter: "drop-shadow(0px 0px 5px white) ",
                              paddingTop: "5px",
                              paddingBottom: "5px",
                            }}
                          />
                          <div className="right">
                            <img
                              className="rarity"
                              alt="rarity"
                              src={displayCharacter.lightcone.rarity}
                            ></img>

                            <div className="row">
                              <div
                                className="circle"
                                style={{
                                  color: displayCharacter.lc.rank_font_color,
                                  background: displayCharacter.lc.rank_bg_color,
                                }}
                              >
                                {displayCharacter.lc.rank}
                              </div>
                              <h4 className="level">
                                LV {displayCharacter.lc.l}
                              </h4>
                            </div>
                            {
                              // Even Number Chinese
                              (languageContext.language === "zh-cn" ||
                                languageContext.language === "zh-tw") &&
                              displayCharacter.lightcone.translation[
                                languageContext.language
                              ].length %
                                2 ===
                                0 ? (
                                <h4
                                  className="name"
                                  style={{
                                    fontSize: "13px",
                                    paddingLeft: "5px",
                                    paddingRight: "5px",
                                  }}
                                >
                                  {
                                    displayCharacter.lightcone.translation[
                                      languageContext.language
                                    ]
                                  }
                                </h4>
                              ) : // Odd Number Chinese
                              languageContext.language === "zh-cn" ||
                                languageContext.language === "zh-tw" ? (
                                <h4
                                  className="name"
                                  style={{
                                    fontSize: "14px",
                                    paddingLeft: "5px",
                                    paddingRight: "5px",
                                  }}
                                >
                                  {
                                    displayCharacter.lightcone.translation[
                                      languageContext.language
                                    ]
                                  }
                                </h4>
                              ) : (
                                <h4
                                  className="name"
                                  style={{
                                    fontSize: "11px",
                                    paddingLeft: "5px",
                                    paddingRight: "5px",
                                  }}
                                >
                                  {
                                    displayCharacter.lightcone.translation[
                                      languageContext.language
                                    ]
                                  }
                                </h4>
                              )
                            }
                          </div>
                        </div>
                      ) : (
                        <div></div>
                      )}
                    </div>
                  </div>
                  <div className="thirdColumn">
                    <div className="skill">
                      {typeof displayCharacter.s !== "undefined" &&
                      displayCharacter.s.length >= 4 ? (
                        <div style={{ display: "flex" }}>
                          <div className="left">
                            {displayCharacter.s.slice(0, 4).map((item, i) => {
                              return (
                                <div key={i}>
                                  <div className="circle">{item.l}</div>
                                  <img
                                    alt={item.i}
                                    src={item.image}
                                    style={{
                                      WebkitFilter:
                                        "drop-shadow(0px 0px 5px white)",
                                    }}
                                  />
                                </div>
                              );
                            })}
                            {displayCharacter.s.slice(4).map((item, i) => {
                              return (
                                <div key={i}>
                                  <div className="circle">x{item.l}</div>
                                  <img
                                    alt={item.i}
                                    src={item.image}
                                    style={{
                                      WebkitFilter:
                                        "drop-shadow(0px 0px 5px white)",
                                    }}
                                  />
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      ) : (
                        <div></div>
                      )}
                    </div>
                  </div>
                  <div className="forthColumn">
                    <div className="stats">
                      {typeof displayCharacter.property !== "undefined" ? (
                        <div>
                          {displayCharacter.property.map((item, i) => {
                            return (
                              <div
                                key={item.id}
                                className={
                                  "stat stat-height-" +
                                  displayCharacter.property.length +
                                  " property-" +
                                  item.id
                                }
                                onMouseOver={() => {
                                  highlightClass(item.id);
                                }}
                                onMouseLeave={() => {
                                  dehighlightClass(item.id);
                                }}
                              >
                                <div className="left">
                                  <img
                                    alt={item.name}
                                    className="icon"
                                    src={item.image}
                                  />
                                </div>
                                <div className="right-top">
                                  <div className="name">
                                    <h2>{item.name}</h2>
                                  </div>
                                  <h2>{item.total}</h2>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      ) : (
                        <div></div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="secondRow">
                  <div className="top-summary">
                    <h2 className="relic-summary-title">
                      {language.relic_summary[languageContext.language]}
                      {" : "}
                    </h2>
                    <div className="relic-summary-score">
                      <h2>{displayCharacter.total_score}</h2>
                      <h2 className={"rank-" + displayCharacter.total_grade}>
                        {displayCharacter.total_grade}
                      </h2>
                    </div>
                  </div>
                  {typeof displayCharacter.rl !== "undefined" ? (
                    <div className="relics">
                      {displayCharacter.rl.map((item, i) => {
                        return (
                          <div
                            key={item.i}
                            className={
                              item.r === 3
                                ? "relic three-star"
                                : item.r === 4
                                ? "relic four-star"
                                : item.r === 5
                                ? "relic five-star"
                                : "relic two-star"
                            }
                          >
                            <div className="relicTop">
                              {typeof item.set === "number" ? (
                                <div className="relic-set">
                                  <div
                                    className="relic-set-desc"
                                    style={{ backgroundColor: item.set_color }}
                                  >
                                    <h4>{item.set}</h4>
                                  </div>
                                </div>
                              ) : (
                                <div></div>
                              )}
                              <img
                                className="relic-image"
                                style={{ cursor: "pointer" }}
                                title={item.name}
                                alt={item.i}
                                src={item.image}
                              />
                              <h4 className="level">+ {item.l}</h4>
                              <div
                                className={
                                  "main-stat-container property-" + item.m.n
                                }
                                title={item.score_text}
                                onMouseOver={() => {
                                  highlightClass(item.m.n);
                                }}
                                onMouseLeave={() => {
                                  dehighlightClass(item.m.n);
                                }}
                              >
                                <div className="inner">
                                  <img
                                    className="main-stat"
                                    alt={item.m.name}
                                    src={item.m.image}
                                  ></img>
                                  <h4 className="main-value">{item.m.v}</h4>
                                </div>
                                <h4 className="description">{item.m.name}</h4>
                              </div>
                              <div className="vertical_line"></div>
                            </div>

                            <div className="horizontal_line"></div>
                            <div className="relic-score">
                              <h4 className="score-title">
                                {language.score[languageContext.language]}
                              </h4>
                              <h4 className={"value rank-" + item.grade}>
                                {item.score}
                              </h4>
                              <h4 className={"value rank-" + item.grade}>
                                {item.grade}
                              </h4>
                            </div>
                            <div className="horizontal_line"></div>
                            <div
                              className="subproperties"
                              title={item.score_text}
                            >
                              {item.s.map((subp, i) => {
                                return (
                                  <div
                                    key={i}
                                    className={"subproperty property-" + subp.n}
                                    onMouseOver={() => {
                                      highlightClass(subp.n);
                                    }}
                                    onMouseLeave={() => {
                                      dehighlightClass(subp.n);
                                    }}
                                  >
                                    <div className="inner">
                                      <h4
                                        className={
                                          "description score-" + subp.score
                                        }
                                      >
                                        {subp.name}
                                      </h4>
                                    </div>
                                    <div className="sub-value-container">
                                      {subp.e !== "undefined" && subp.e > 1 ? (
                                        <h4 className="enhancement">
                                          +{subp.e - 1}
                                        </h4>
                                      ) : (
                                        <h4 className="empty-enhancement">
                                          +0
                                        </h4>
                                      )}
                                      <h4
                                        className={
                                          "sub-value score-" + subp.score
                                        }
                                      >
                                        {subp.v}
                                      </h4>
                                    </div>
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  ) : (
                    <div></div>
                  )}
                </div>
              </div>
            </div>
            <div
              className="button"
              onClick={async () => {
                if (
                  typeof displayCharacter.u === "undefined" ||
                  typeof displayCharacterId.name === "undefined"
                )
                  return;

                const dataUrl = await htmlToImage.toPng(
                  portraitDownloadMobileReference.current
                );

                // download image
                const link = document.createElement("a");
                link.download =
                  displayCharacter.u + "_" + displayCharacterId.name + ".png";
                link.href = dataUrl;
                link.click();
              }}
            >
              <h2>{language.download[languageContext.language]}</h2>
            </div>
            <div className="options">
              <div className="option">
                <h4>
                  {language.image_border_colour[languageContext.language]}
                </h4>
                <SketchPicker
                  color={imagePortraitMobileBorderColour}
                  onChangeComplete={handlePortraitMobileChangeComplete}
                />
                <h4>
                  {language.image_border_radius[languageContext.language]}
                </h4>
                <Slider
                  min={0}
                  max={100}
                  value={imagePortraitMobileBorderRadius}
                  style={{ width: "90%" }}
                  onChange={(e, val) => {
                    setPortraitMobileImageBorderRadius(val);
                  }}
                ></Slider>
              </div>
              <input
                style={{ display: "none" }}
                ref={portraitMobileImageFile}
                type="file"
                onChange={(event) => {
                  try {
                    setPortraitMobileImage(
                      URL.createObjectURL(event.target.files[0])
                    );
                  } catch (e) {}
                }}
              />
              <input
                style={{ display: "none" }}
                ref={portraitMobileBackgroundFile}
                type="file"
                onChange={(event) => {
                  try {
                    setPortraitMobileBackground(
                      "url(" + URL.createObjectURL(event.target.files[0]) + ")"
                    );
                  } catch (e) {}
                }}
              />
            </div>
            <br></br>
          </div>

          <div className="display-ads horizontal-ads">
            <HorizontalDisplayAds />
          </div>
          <div className="character_card_section" id="character_card_section">
            <h2 className="title">
              {language.character_card[languageContext.language]}
            </h2>
            <div className="scrollViewer" id="scroll-container">
              <div
                id="displayContainer"
                className="displayContainer"
                ref={landscapeDownloadReference}
                style={{ backgroundImage: landscapeBackground }}
              >
                <div className="firstColumn">
                  <div className="name">
                    {typeof displayCharacterId === "object" ? (
                      <h2 className={languageContext.language + "-" + displayCharacterId.id}>
                        {
                          displayCharacterId.translation[
                            languageContext.language
                          ]
                        }
                      </h2>
                    ) : (
                      <h2></h2>
                    )}
                    {hideName === false ? (
                      <h4>- {displayCharacter.n}</h4>
                    ) : (
                      <p></p>
                    )}
                  </div>
                  <div className="level">
                    <h4>
                      LV {displayCharacter.l} / {displayCharacter.maxLevel}
                    </h4>
                  </div>
                  <div className="eidolon">
                    {displayCharacter.r >= 1 ? (
                      <img
                        alt="e1"
                        src={displayCharacterId.e1}
                        style={{
                          WebkitFilter:
                            "drop-shadow(0px 0px 5px white) drop-shadow(0px 0px 15px rgba(255, 255, 255, 0.7)) ",
                        }}
                      />
                    ) : (
                      <img
                        alt="e1"
                        className="locked"
                        src={displayCharacterId.e1}
                      />
                    )}
                    {displayCharacter.r >= 2 ? (
                      <img
                        alt="e2"
                        src={displayCharacterId.e2}
                        style={{
                          WebkitFilter:
                            "drop-shadow(0px 0px 5px white) drop-shadow(0px 0px 15px rgba(255, 255, 255, 0.7)) ",
                        }}
                      />
                    ) : (
                      <img
                        alt="e2"
                        className="locked"
                        src={displayCharacterId.e2}
                      />
                    )}
                    {displayCharacter.r >= 3 ? (
                      <img
                        alt="e3"
                        src={displayCharacterId.e3}
                        style={{
                          WebkitFilter:
                            "drop-shadow(0px 0px 5px white) drop-shadow(0px 0px 15px rgba(255, 255, 255, 0.7)) ",
                        }}
                      />
                    ) : (
                      <img
                        alt="e3"
                        className="locked"
                        src={displayCharacterId.e3}
                      />
                    )}
                    {displayCharacter.r >= 4 ? (
                      <img
                        alt="e4"
                        src={displayCharacterId.e4}
                        style={{
                          WebkitFilter:
                            "drop-shadow(0px 0px 5px white) drop-shadow(0px 0px 15px rgba(255, 255, 255, 0.7)) ",
                        }}
                      />
                    ) : (
                      <img
                        alt="e4"
                        className="locked"
                        src={displayCharacterId.e4}
                      />
                    )}
                    {displayCharacter.r >= 5 ? (
                      <img
                        alt="e5"
                        src={displayCharacterId.e5}
                        style={{
                          WebkitFilter:
                            "drop-shadow(0px 0px 5px white) drop-shadow(0px 0px 15px rgba(255, 255, 255, 0.7)) ",
                        }}
                      />
                    ) : (
                      <img
                        alt="e5"
                        className="locked"
                        src={displayCharacterId.e5}
                      />
                    )}
                    {displayCharacter.r >= 6 ? (
                      <img
                        alt="e6"
                        src={displayCharacterId.e6}
                        style={{
                          WebkitFilter:
                            "drop-shadow(0px 0px 4px white) drop-shadow(0px 0px 15px rgba(255, 255, 255, 0.7)) ",
                        }}
                      />
                    ) : (
                      <img
                        alt="e6"
                        className="locked"
                        src={displayCharacterId.e6}
                      />
                    )}
                  </div>
                  <div className="uid">
                    {hideUid === false ? (
                      <h4>{displayCharacter.u}</h4>
                    ) : (
                      <p></p>
                    )}
                  </div>
                </div>

                <div className="secondColumn"   onMouseEnter={()=> {
                    const landscapeButtons = document.getElementById("landscape-view-buttons");
                    landscapeButtons.style.visibility = "visible";
                  }}
                  onMouseLeave={()=> {
                    const landscapeButtons = document.getElementById("landscape-view-buttons");
                    landscapeButtons.style.visibility = "collapse";
                  }}>
                  <img
                    alt="profile"
                    id="landscape-image-container"
                    src={landscapeImage}
                  ></img>
                </div>
                    <div className="buttons" id="landscape-view-buttons" onMouseEnter={()=> {
                    const landscapeButtons = document.getElementById("landscape-view-buttons");
                    landscapeButtons.style.visibility = "visible";
                  }}
                  onMouseLeave={()=> {
                    const landscapeButtons = document.getElementById("landscape-view-buttons");
                    landscapeButtons.style.visibility = "collapse";
                  }}>
                      <h2
                        className="button imageButton"
                        onClick={() => {
                          landscapeImageFile.current.click();
                        }}
                      >
                        <Image style={{ marginTop: "-3px" }} />
                        {language.choose_image[languageContext.language]}
                      </h2>
                      <div className="navigation">
                        <h2
                          className="button imageButton"
                          onClick={() => {
                            setLandscapeImageOffsetX(
                              imageLandscapeOffsetX - 10
                            );
                          }}
                        >
                          ←
                        </h2>
                        <h2
                          className="button imageButton"
                          onClick={() => {
                            setLandscapeImageOffsetX(
                              imageLandscapeOffsetX + 10
                            );
                          }}
                        >
                          →
                        </h2>
                        <h2
                          className="button imageButton"
                          onClick={() => {
                            setLandscapeImageOffsetY(
                              imageLandscapeOffsetY - 10
                            );
                          }}
                        >
                          ↑
                        </h2>
                        <h2
                          className="button imageButton"
                          onClick={() => {
                            setLandscapeImageOffsetY(
                              imageLandscapeOffsetY + 10
                            );
                          }}
                        >
                          ↓
                        </h2>
                      </div>
                      <div className="scale">
                        <h2
                          className="button imageButton"
                          onClick={() => {
                            setLandscapeImageScale(
                              imageLandscapeScale + 10
                            );
                          }}
                        >
                          +
                        </h2>
                        <h2
                          className="button imageButton"
                          onClick={() => {
                            if (imageLandscapeScale > 20)
                              setLandscapeImageScale(
                                imageLandscapeScale - 10
                              );
                          }}
                        >
                          -
                        </h2>
                      </div>
                      <h2
                        className="button imageButton"
                        onClick={() => {
                          landscapeBackgroundFile.current.click();
                        }}
                      >
                        <Image style={{ marginTop: "-3px" }} />
                        {language.choose_background[languageContext.language]}
                      </h2>
                    </div>
                <div className="thirdColumn">
                  <div className="skill">
                    {typeof displayCharacter.s !== "undefined" &&
                    displayCharacter.s.length >= 4 ? (
                      <div style={{ display: "flex" }}>
                        <div className="left">
                          {displayCharacter.s.slice(0, 4).map((item, i) => {
                            return (
                              <div key={i}>
                                <div className="circle">{item.l}</div>
                                <img
                                  alt={item.i}
                                  src={item.image}
                                  style={{
                                    WebkitFilter:
                                      "drop-shadow(0px 0px 5px white)",
                                  }}
                                />
                              </div>
                            );
                          })}
                        </div>
                        <div className="right">
                          {displayCharacter.s.slice(4).map((item, i) => {
                            return (
                              <div
                                className={"property-" + item.i}
                                key={i}
                                onMouseOver={() => {
                                  highlightClass(item.i);
                                }}
                                onMouseLeave={() => {
                                  dehighlightClass(item.i);
                                }}
                              >
                                <div className="circle">x{item.l}</div>
                                <img
                                  alt={item.i}
                                  src={item.image}
                                  style={{
                                    WebkitFilter:
                                      "drop-shadow(0px 0px 5px white)",
                                  }}
                                />
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    ) : (
                      <div></div>
                    )}
                  </div>
                  <div className="lightcone lightcone-stats">
                    {typeof displayCharacter.lightcone !== "undefined" ? (
                      <div
                        className={
                          "card " + displayCharacter.lightcone.rarity_number
                        }
                      >
                        <img
                          className="lightcone-image"
                          alt="lightcone"
                          src={displayCharacter.lightcone.image}
                          style={{
                            WebkitFilter: "drop-shadow(0px 0px 5px white) ",
                            paddingTop: "5px",
                            paddingBottom: "5px",
                          }}
                        />
                        <img
                          className="rarity"
                          alt="rarity"
                          src={displayCharacter.lightcone.rarity}
                        ></img>
                        <div className="row">
                          <div
                            className="circle"
                            style={{
                              color: displayCharacter.lc.rank_font_color,
                              background: displayCharacter.lc.rank_bg_color,
                            }}
                          >
                            {displayCharacter.lc.rank}
                          </div>
                          <h4 className="level">LV {displayCharacter.lc.l}</h4>
                        </div>
                        {
                          // Even Number Chinese
                          (languageContext.language === "zh-cn" ||
                            languageContext.language === "zh-tw") &&
                          displayCharacter.lightcone.translation[
                            languageContext.language
                          ].length %
                            2 ===
                            0 ? (
                            <h4
                              className="name"
                              style={{
                                fontSize: "18px",
                                paddingLeft: "8px",
                                paddingRight: "8px",
                              }}
                            >
                              {
                                displayCharacter.lightcone.translation[
                                  languageContext.language
                                ]
                              }
                            </h4>
                          ) : // Odd Number Chinese
                          languageContext.language === "zh-cn" ||
                            languageContext.language === "zh-tw" ? (
                            <h4
                              className="name"
                              style={{
                                fontSize: "15px",
                                paddingLeft: "8px",
                                paddingRight: "8px",
                              }}
                            >
                              {
                                displayCharacter.lightcone.translation[
                                  languageContext.language
                                ]
                              }
                            </h4>
                          ) : (
                            <h4 className="name">
                              {
                                displayCharacter.lightcone.translation[
                                  languageContext.language
                                ]
                              }
                            </h4>
                          )
                        }
                      </div>
                    ) : (
                      <div></div>
                    )}
                  </div>
                </div>

                <div className="forthColumn">
                  <div className="stats">
                    {typeof displayCharacter.property !== "undefined" ? (
                      <div>
                        {displayCharacter.property.map((item, i) => {
                          return (
                            <div
                              key={item.id}
                              className={
                                "stat stat-height-" +
                                displayCharacter.property.length +
                                " property-" +
                                item.id
                              }
                              onMouseOver={() => {
                                highlightClass(item.id);
                              }}
                              onMouseLeave={() => {
                                dehighlightClass(item.id);
                              }}
                            >
                              <img
                                alt={item.name}
                                className="icon"
                                src={item.image}
                              />
                              <h2>{item.name}</h2>
                              {String(item.base).includes("%") ? (
                                <div className={"value " + hideSubvalue}>
                                  <h2>{item.base}</h2>
                                </div>
                              ) : (
                                <div className={"value " + hideSubvalue}>
                                  <h2>{Number(item.total)}</h2>
                                  {
                                    // Base + Addition? + Lightcone?
                                    item.arrangement === 1 ? (
                                      <div className={"subvalue " + hideSubvalue}>
                                        <h4>{item.base}</h4>
                                        {typeof item.lightcone !==
                                        "undefined" ? (
                                          <h4 className="addition lightcone-stats">
                                            + {item.lightcone}
                                          </h4>
                                        ) : (
                                          <p></p>
                                        )}
                                        {item.addition !== "0" ? (
                                          <h4 className="addition">
                                            + {item.addition}
                                          </h4>
                                        ) : (
                                          <p></p>
                                        )}
                                      </div>
                                    ) : item.arrangement === 2 ? (
                                      <div className={"subvalue " + hideSubvalue}>
                                        <h4 className="addition">
                                          {item.addition}
                                        </h4>
                                      </div>
                                    ) : item.arrangement === 3 ? (
                                      <div className={"subvalue " + hideSubvalue}>
                                        <h4 className="addition lightcone-stats">
                                          {item.lightcone}
                                        </h4>
                                        {item.addition !== "0" ? (
                                          <h4 className="addition">
                                            + {item.addition}
                                          </h4>
                                        ) : (
                                          <p></p>
                                        )}
                                      </div>
                                    ) : item.arrangement === 4 ? (
                                      <div className={"subvalue " + hideSubvalue}>
                                        <h4 className="addition lightcone-stats">
                                          {item.lightcone}
                                        </h4>
                                      </div>
                                    ) : (
                                      // No need display
                                      <div></div>
                                    )
                                  }
                                </div>
                              )}
                            </div>
                          );
                        })}
                      </div>
                    ) : (
                      <div></div>
                    )}
                  </div>
                </div>

                <div className="fifthColumn">
                  <div className="relics">
                    {typeof displayCharacter.rl !== "undefined" ? (
                      <div>
                        {displayCharacter.rl.map((item, i) => {
                          return (
                            <div key={item.i} className="container">
                              <div
                                key={item.i}
                                className={
                                  item.r === 3
                                    ? "relic three-star"
                                    : item.r === 4
                                    ? "relic four-star"
                                    : item.r === 5
                                    ? "relic five-star"
                                    : "relic two-star"
                                }
                              >
                                <div className="left">
                                  {typeof item.set === "number" ? (
                                    <div className="relic-set">
                                      <div
                                        className="relic-set-desc"
                                        style={{
                                          backgroundColor: item.set_color,
                                        }}
                                      >
                                        <h4>{item.set}</h4>
                                      </div>
                                    </div>
                                  ) : (
                                    <div></div>
                                  )}
                                  <img
                                    className="relic-image"
                                    style={{ cursor: "pointer" }}
                                    title={item.name}
                                    alt={item.i}
                                    src={item.image}
                                  />
                                  <h4 className="level">+ {item.l}</h4>
                                  <div
                                    className={
                                      "main-stat-container property-" + item.m.n
                                    }
                                    title={item.m.name}
                                    onMouseOver={() => {
                                      highlightClass(item.m.n);
                                    }}
                                    onMouseLeave={() => {
                                      dehighlightClass(item.m.n);
                                    }}
                                  >
                                    <img
                                      className="main-stat"
                                      alt={item.m.name}
                                      src={item.m.image}
                                    ></img>
                                    <h4 className="main-value">{item.m.v}</h4>
                                  </div>
                                  <div className="vertical_line"></div>
                                  <div className="subproperties first">
                                    {item.s.slice(0, 2).map((subp, i) => {
                                      return (
                                        <div
                                          key={i}
                                          className={
                                            "subproperty property-" + subp.n
                                          }
                                          title={subp.name}
                                          onMouseOver={() => {
                                            highlightClass(subp.n);
                                          }}
                                          onMouseLeave={() => {
                                            dehighlightClass(subp.n);
                                          }}
                                        >
                                          <img
                                            className={"sub-stat " + subp.n}
                                            alt={subp.n}
                                            src={subp.image}
                                          ></img>
                                          <h4
                                            className={
                                              "sub-value score-" + subp.score
                                            }
                                          >
                                            {subp.v}
                                          </h4>
                                        </div>
                                      );
                                    })}
                                  </div>
                                  <div className="subproperties second">
                                    {item.s.slice(2, 4).map((subp, i) => {
                                      return (
                                        <div
                                          key={subp.n}
                                          className={
                                            "subproperty property-" + subp.n
                                          }
                                          title={subp.name}
                                          onMouseOver={() => {
                                            highlightClass(subp.n);
                                          }}
                                          onMouseLeave={() => {
                                            dehighlightClass(subp.n);
                                          }}
                                        >
                                          <img
                                            className="sub-stat"
                                            alt={subp.n}
                                            src={subp.image}
                                          ></img>
                                          <h4
                                            className={
                                              "sub-value score-" + subp.score
                                            }
                                          >
                                            {subp.v}
                                          </h4>
                                        </div>
                                      );
                                    })}
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    ) : (
                      <div></div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div
              className="button"
              onClick={async () => {
                if (
                  typeof displayCharacter.u === "undefined" ||
                  typeof displayCharacterId.name === "undefined"
                )
                  return;

                const dataUrl = await htmlToImage.toPng(
                  landscapeDownloadReference.current
                );

                // download image
                const link = document.createElement("a");
                link.download =
                  displayCharacter.u + "_" + displayCharacterId.name + ".png";
                link.href = dataUrl;
                link.click();
              }}
            >
              <h2>{language.download[languageContext.language]}</h2>
            </div>
            <div className="options">

              <div className="option">
                <h4>
                  {language.image_border_colour[languageContext.language]}
                </h4>
                <SketchPicker
                  color={imageLandscapeBorderColour}
                  onChangeComplete={handleLandscapeChangeComplete}
                />
                <h4>
                  {language.image_border_radius[languageContext.language]}
                </h4>
                <Slider
                  min={0}
                  max={100}
                  value={imageLandscapeBorderRadius}
                  style={{ width: "90%" }}
                  onChange={(e, val) => {
                    setLandscapeImageBorderRadius(val);
                  }}
                ></Slider>
              </div>
              <input
                style={{ display: "none" }}
                type="file"
                ref={landscapeImageFile}
                onChange={(event) => {
                  try {
                    setLandscapeImage(
                      URL.createObjectURL(event.target.files[0])
                    );
                  } catch (e) {}
                }}
              />
              <input
                style={{ display: "none" }}
                type="file"
                ref={landscapeBackgroundFile}
                onChange={(event) => {
                  try {
                    setLandscapeBackground(
                      "url(" + URL.createObjectURL(event.target.files[0]) + ")"
                    );
                  } catch (e) {}
                }}
              />
            </div>
            <br></br>
          </div>
        </div>
      )}

      <Footer />
    </div>
  );
};

export default Profile;
